import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import {getNestsForUserId, writeNest,deleteNest} from './nestAPI';
import {WEBSOCKET_MESSAGE} from "@giantmachines/redux-websocket";

export function generateSaveNestThunk(id, name){
    return async function saveNestThunk(dispatch, getState) {   
        dispatch({ type: 'nests/spinner', payload:true });     
        const response = await writeNest({id, name});
        console.log(response);
        if(response.ok){
            const nest = await response.json();            
            dispatch({ type: 'nests/added', payload:nest });
            dispatch({ type: 'nests/spinner', payload:false });

        }
        else{
            //TODO HANDLE ERRORS
        }
    }
}

export function generateDeleteNestThunk(id){
    return async function deleteNestThunk(dispatch, getState) {
        console.log('calling API');
        const response = await deleteNest(id);
        console.log('after  calling API===>  '+response);

        console.log(response);
        if(response.ok){
            dispatch({ type: 'nests/deleted', payload: {id} });
        }
        else{
            //TODO HANDLE ERRORS
        }
    }
}

export const fetchNestsForUser = createAsyncThunk(
    'nests/fetchNests',
    async (userId, thunkAPI) => {
        const response = await getNestsForUserId(userId);
        if (response.ok)
        {
            console.info('got ok response from backend')
            console.debug(response)
            return response.json();
        }
        else {
            //handle cases
            switch (response.status){
                default:
                case 500:
                    throw new ErrorEvent("Server Error");
            }
        }

    }
);

export const nestSlice = createSlice({
        name: 'nests',
        initialState: {
            //status: 'idle' | 'loading' | 'succeeded' | 'failed',
            status: 'idle',
            error: null,
            records: null,
            spinnerClose: null
        },
        reducers: {
            spinner(state, action) {
                console.log('Spinner action ', action.payload);
                state.spinnerClose=action.payload;
            },
            added(state, action) {
                console.log('added record', action.payload);
                state.records[action.payload.id]=action.payload;
            },
            deleted(state, action){
                console.log('deleted record id=', action.payload.id);
                delete state.records[action.payload.id];
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase("REDUX_WEBSOCKET::"+WEBSOCKET_MESSAGE, (state, action) => {
                    console.log("nests/REDUX_WEBSOCKET::"+WEBSOCKET_MESSAGE,action);
                    if("message" in action.payload){
                        const message = JSON.parse(action.payload.message);
                        if("item_type" in message && message.item_type === "nest"){
                            if("action_type" in message && message.action_type === "INSERT"){
                                if("item" in message){
                                    state.records[message.item.id] = message.item;
                                }
                                if("items" in message){
                                    for(const item in message.items){
                                        state.records[message.items[item].id] = message.items[item];
                                    }
                                }
                            }
                        }
                    }
                })
                .addCase(fetchNestsForUser.pending, (state) => {
                    state.status = 'loading';
                })
                .addCase(fetchNestsForUser.rejected,(state => {
                    state.status = 'failed';
                }))
                .addCase(fetchNestsForUser.fulfilled, (state, action) => {
                    state.status = 'succeeded';
                    console.log('succeeded getting nests',action.payload);
                    state.records = action.payload;
                    console.log('state now',state.records);
                });
        },
    })

export const selectAllNests = (state) =>  state.nests.records ? Object.values(state.nests.records) : []
export const selectNestById = (state,id) => state.nests.records[id]
export const closeSpinner = (state) => state.nests.spinnerClose

export default nestSlice.reducer
