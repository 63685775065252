import React,{useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Spinner, Button} from "@salesforce/design-system-react";
import {fetchNestsForUser, selectAllNests} from "./nestSlice";
import {Template} from "../template/Template";
import {NestInfoPanel} from "./NestInfoPanel";
import {NestMainPanel} from "./NestMainPanel";

function Nests(props) {
    const dispatch = useDispatch();
    const nests = useSelector(selectAllNests);
    const nestStatus = useSelector(state => state.nests.status);
    useEffect(() => {
        if(nestStatus === 'idle'){
            dispatch(fetchNestsForUser());
        }
    },[nestStatus,dispatch])

    if(nests==null) {
        if (nestStatus === 'idle' || nestStatus === 'loading') {
            return (
                <div className='panel'>
                    <div style={{position: 'relative', height: '10rem'}}>
                        <Spinner
                            size="large"
                            variant="brand"
                            assistiveText={{label: 'Loading your nests'}}/>
                    </div>
                </div>);
        } else if (nestStatus === 'succeeded' || nestStatus === 'failed') {
            return (
                <div className='panel'>
                    <Button variant="brand" label="Nest Load Failed" onClick={() => dispatch(fetchNestsForUser())}/>
                </div>);
        }
        else{
            return (
                <div className='panel'>
                    <Button variant="brand" label={nestStatus+" Nest Load Failed "} onClick={() => dispatch(fetchNestsForUser())}/>
                </div>);
        }
    }
    else{
        return (<div className="scrollbar"><Template info={<NestInfoPanel/>} main={<NestMainPanel/>}/></div>);
    }

}

export {Nests};
