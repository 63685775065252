import config from '../../config.js';

export async function getByUserId() {
    return await fetch(config.SERVER_URL()+"/users/me",{
        credentials: 'include',
        mode: "cors"
    });
}

export async function setUserForExport(updateUser) {
    console.log('setUserForExport user put method called'+JSON.stringify(updateUser));
    return await fetch(config.SERVER_URL()+"/status/ui",{
        method: 'POST',
        body: JSON.stringify(updateUser),
        credentials: 'include',
        mode: "cors"
    });
}
