import config from "../../config.js";

export async function getNamesForUserId(userId) {
    return await fetch(config.SERVER_URL()+"/names",{
        mode:'cors',
        credentials: 'include'
    });
}

export async function writeName(nest) {
    console.log('writing name',nest);
    return await fetch(config.SERVER_URL()+"/names",{
        method: 'POST',
        body: JSON.stringify(nest.name),
        mode:'cors',
        credentials: 'include'
    });
}

export async function updateName(id, updated_properties) {
    console.log('updating name id='+id, updated_properties);
    return await fetch(config.SERVER_URL()+"/names/"+id,{
        method: 'PUT',
        body: JSON.stringify(updated_properties),
        mode:'cors',
        credentials: 'include'
    });
}

export async function deleteName(id) {
    console.log(`deleting name ${id}`);
    return await fetch(config.SERVER_URL()+"/names/"+id,{
        method: 'DELETE',
        body: JSON.stringify({id}),
        mode:'cors',
        credentials: 'include'
    });
}
