import React, {useEffect, useState} from "react";
import {Combobox, IconSettings, Input} from "@salesforce/design-system-react";


const stateValues = [
    {label: 'AK', value: 'AK', id:'AK'},
    {label: 'AL', value: 'AL', id:'AL'},
    {label: 'AR', value: 'AR', id:'AR'},
    {label: 'AZ', value: 'AZ', id:'AZ'},
    {label:'CA',value:'CA',id:'CA'},
    {label:'CO',value:'CO',id:'CO'},
    {label:'CT',value:'CT',id:'CT'},
    {label:'DC',value:'DC',id:'DC'},
    {label:'DE',value:'DE',id:'DE'},
    {label:'FL',value:'FL',id:'FL'},
    {label:'GA',value:'GA',id:'GA'},
    {label:'HI',value:'HI',id:'HI'},
    {label:'IA',value:'IA',id:'IA'},
    {label:'ID',value:'ID',id:'ID'},
    {label:'IL',value:'IL',id:'IL'},
    {label:'IN',value:'IN',id:'IN'},
    {label:'KS',value:'KS',id:'KS'},
    {label:'KY',value:'KY',id:'KY'},
    {label:'LA',value:'LA',id:'LA'},
    {label:'MA',value:'MA',id:'MA'},
    {label:'MD',value:'MD',id:'MD'},
    {label:'ME',value:'ME',id:'ME'},
    {label:'MI',value:'MI',id:'MI'},
    {label:'MN',value:'MN',id:'MN'},
    {label:'MO',value:'MO',id:'MO'},
    {label:'MS',value:'MS',id:'MS'},
    {label:'MT',value:'MT',id:'MT'},
    {label:'NC',value:'NC',id:'NC'},
    {label:'ND',value:'ND',id:'ND'},
    {label:'NE',value:'NE',id:'NE'},
    {label:'NH',value:'NH',id:'NH'},
    {label:'NJ',value:'NJ',id:'NJ'},
    {label:'NM',value:'NM',id:'NM'},
    {label:'NV',value:'NV',id:'NV'},
    {label:'NY',value:'NY',id:'NY'},
    {label:'OH',value:'OH',id:'OH'},
    {label:'OK',value:'OK',id:'OK'},
    {label:'OR',value:'OR',id:'OR'},
    {label:'PA',value:'PA',id:'PA'},
    {label:'RI',value:'RI',id:'RI'},
    {label:'SC',value:'SC',id:'SC'},
    {label:'SD',value:'SD',id:'SD'},
    {label:'TN',value:'TN',id:'TN'},
    {label:'TX',value:'TX',id:'TX'},
    {label:'UT',value:'UT',id:'UT'},
    {label:'VA',value:'VA',id:'VA'},
    {label:'VT',value:'VT',id:'VT'},
    {label:'WA',value:'WA',id:'WA'},
    {label:'WI',value:'WI',id:'WI'},
    {label:'WV',value:'WV',id:'WV'},
    {label:'WY',value:'WY',id:'WY'},
]

function validateZip(zipCode){
    const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    return !(zipCode !== '' && !zipRegex.exec(zipCode));
}

function validateStreet(street){
    let returnBool = false;
    if(!street){
        returnBool = true;
    }else if(street && street.length < 256){
        returnBool = true;
    }
    console.log('returnBool----'+returnBool);
    return returnBool;
}

function validateCityLength(city){
    let returnBool = false;
    if(!city){
        returnBool = true;
    }else if(city && (city.length > 2 && city.length < 41)){
        returnBool = true;
    }
    return returnBool;
}

function validateCitySpecChar(city){
    const CityFormat = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    let returnBool = false;
    if(!city){
        returnBool = true;
    }else if(city && !(CityFormat.test(city))){
        returnBool = true;
    }
    return returnBool;
}

function validateState(state){
    let StateVar = stateValues.find(option => option.value === state) ? true : false;
    console.log('State---',StateVar);
    let returnBool = false;
    if(!state){
        returnBool = true;
    }else if(StateVar){
        returnBool = true;
    }
    return returnBool;
}

export default function AddressSection(props){
    const[street,setStreet] = useState('');
    const[city,setCity] = useState('');
    const[selectedState,setSelectedState] = useState('');
    const[zip,setZip] = useState('');
    let isValidZip = validateZip(props.zip);
    let isValidStreet= validateStreet(props.street);
    let isValidCityLength  = validateCityLength(props.city);
    let isValidCitySpec  = validateCitySpecChar(props.city);
    let isValidState  = validateState(props.adState);
    let fieldValidation = {
        zip:"",
        street:"",
        city:"",
        state:""
    };

    const [validation, setValidation] = useState(fieldValidation);

    useEffect(() => {
        setStreet(props.street);
        setCity(props.city);
        if(props.adState){
            const foundState = stateValues.filter(st => st.value === props.adState);
            if(foundState.length===1){
                setSelectedState(props.adState);
            }
        }
        else{
            setSelectedState('');
        }
        setZip(props.zip);

        if(props.isZipValid){
            props.isZipValid(isValidZip);
        }
        if(props.isStreetValid){
            props.isStreetValid(isValidStreet);
        }
        if(props.isCityValidLength){
            props.isCityValidLength(isValidCityLength);
        }
        if(props.isCityValidSpec){
            props.isCityValidSpec(isValidCitySpec);
        }
        if(props.isStateValid){
            props.isStateValid(isValidState);
        }
        if(props.editCancelled){
            setValidation({zip :"", street : "" , city : "", state : ""});
        }
    },[props,isValidZip, isValidStreet, isValidCityLength, isValidCitySpec, isValidState]);

    useEffect(() => {

        setValidation({street : !isValidStreet ? "Street name cannot exceed 255 characters" : "", city : !isValidCityLength ? "City must be between 3-40 characters. No special characters": !isValidCitySpec ? "City cannot contain special characters": "",
        zip : !isValidZip ? "ZIP Code must be in either 5-digit or 9-digit format. Example: 12345 or 12345-6789." : "" , state : !isValidState ? "State must be abbreviated or spelled out fully with spaces":""
        });        
    },[validation.zip, validation.city,validation.street, validation.state, isValidStreet , isValidZip, isValidCityLength, isValidCitySpec, isValidState]);
    
    const validateZipInput = (e) => {
        if(!validateZip(e.target.value)){
            console.log(e.target);
            setValidation({zip :"ZIP Code must be in either 5-digit or 9-digit format. Example: 12345 or 12345-6789."});
            isValidZip = false;
            
        }else{
            setValidation({zip :""});
            isValidZip = true;
        }
        if(props.isZipValid){
            props.isZipValid(isValidZip);
        }
    };

    const validateStreetInput = (e) => {
        const streetInput = e.target.value;
        if(streetInput){
            if(streetInput.length > 255){
                console.log(e.target);
                setValidation({street :"Street name cannot exceed 255 characters"});
                isValidStreet = false;
            }else{
                setValidation({street :""});
                isValidStreet = true;
            }
        }else{
            setValidation({street :""});
            isValidStreet = true;
        }
        
        if(props.isStreetValid){
            props.isStreetValid(isValidStreet);
        }
    };

    const validateCityInput = (e) => {
        const cityInput = e.target.value;
        const CityFormat = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

        if(cityInput && (cityInput.length < 3 || cityInput.length > 40)){
            console.log(e.target);
            setValidation({city :"City must be between 3-40 characters. No special characters"});
            isValidCityLength = false;
        }else if(cityInput && (CityFormat.test(cityInput))){
            setValidation({city :"City cannot contain special characters"});
            isValidCitySpec = false;
        }else{
            setValidation({city :""});
            isValidCityLength = true;
            isValidCitySpec = true;
        }
        if(props.isCityValidLength){
            props.isCityValidLength(isValidCityLength);
        }

        if(props.isCityValidSpec){
            props.isCityValidSpec(isValidCitySpec);
        }
    };
    console.log('props address==>'+JSON.stringify(props));


    const handleStateSelect = (event,data) =>{
        console.log('AddressSection state data===>'+JSON.stringify(data));
        props.adStateChange(data.selection[0].value);
        isValidState = true;
        if(props.isStateValid){
            props.isStateValid(isValidState);
        }
    }

    return(
        <div className='addNames'>
            <Input
                label= "Street Address"
                name="street"
                onChange={(e)=>props.streetChange(e.target.value)}
                value={street}
                errorText={validation.street}
                onBlur={(e) => validateStreetInput(e)}
            />
            <Input
                label= "City"
                name="city"
                onChange={(e)=>props.cityChange(e.target.value)}
                value={city}
                errorText={validation.city}
                onBlur={(e) => validateCityInput(e)}
            />
            <div className="slds-grid">
                <div className="slds-col slds-size_1-of-2 slds-p-right_medium">
                    <div className="slds-p-right_x-small">
                        <IconSettings iconPath="/icons">
                            <Combobox
                                id={'stateCombo'}
                                name={"state"}
                                errorText={validation.state}
                                predefinedOptionsOnly={true}
                                inheritWidthOf={"menu"}
                                events={{
                                    onSelect: (event, data) => {
                                        handleStateSelect(event,data)
                                    }
                                }}
                                labels={{
                                    label: 'State',
                                    placeholder: 'State'
                                }}
                                menuMaxWidth={"5px"}
                                options={stateValues}
                                value={selectedState}
                                hasStaticAlignment = {false}
                                menuItemVisibleLength ={5}
                            />
                        </IconSettings>
                    </div>
                </div>
                <div class="slds-col slds-size_1-of-2" >
                    <Input
                        label= "Zip Code"
                        name="zip"
                        onChange={(e)=> props.zipChange(e.target.value)}
                        value={zip}
                        onBlur={(e) => validateZipInput(e)}
                        errorText={validation.zip}
                    />
                </div>
            </div>
        </div>
    )
}
