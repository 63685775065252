import React from 'react';

function NestInfoPanel(props) {
    return (
        <div className="nest-title-group">
            <div className="nest-title-group">
                <h5 className="nest-title col-md-6 col-md-offset-3">Step 1: Define Your Nests</h5>
                <br/>
                <div className="col-md-6 col-md-offset-3 nest-left-margin">The Nests and contacts you enter here will be available in Sales Central, our digital CRM tool, to help power your prospecting outreach. Identifying and assigning contacts into Nests helps capture insights about people you know from your day-to-day life (work, hobbies, etc.).  </div>
                <br/>
                <div className="col-md-6 col-md-offset-3 nest-left-margin">A <b>Nest</b> is a distinct group of people in a sizable community who: </div>
                <br/>
                <div className="col-md-6 col-md-offset-3 nest-left-margin"><b>1.&nbsp;</b>  Are easily identifiable and accessible to you for contacting naturally </div>
                <br/>
                <div className="col-md-6 col-md-offset-3 nest-left-margin"><b>2.&nbsp;</b> Have common needs and the financial means to afford our solutions </div>
                <br/>
                <div className="col-md-6 col-md-offset-3 nest-left-margin"><b>3.&nbsp;</b> Interact and communicate with each other on a regular basis</div>
                <br/>
                <div className="col-md-6 col-md-offset-3 nest-left-margin">Groups in which you play an active role and have established relationships should rank higher on your Nest list.</div>
                <br/>
                <div className="col-md-6 col-md-offset-3 nest-left-margin">While an optional activity on your path to becoming an active agent, successful new agents jumpstart their new careers by developing a prioritized outreach plan and focus on developing specific Nest opportunities.</div>
            </div>

        </div>
    );
}

export { NestInfoPanel };
