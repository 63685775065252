import React from 'react';
import "./name.css";
import {Toast,ToastContainer,IconSettings} from "@salesforce/design-system-react";

function ToastMessages(props){
    let message ='';
    let variantType = '';

    switch (props.errorType){
        case 'DuplicateConsumerFound':
            message = 'A record with this information already exists. Change the values or click Cancel to discard.';
            variantType = 'error';
            break;
        case 'recordSaved':
            message = 'Consumer Added';
            variantType = 'success';
            break;
        default:
            props.close();
            break;
    }

    function closeToast(){
        props.close();
    }

    return(
       <div>
           <IconSettings iconPath="/icons">
				<ToastContainer>
					<Toast
						labels={{
                            heading: message,
						}}
						variant = {variantType}
                        onRequestClose = {closeToast}
                        className={props.errorType === 'recordSaved' ? 'successToastMessage': 'errorToastMessage'}
					/>
				</ToastContainer>
			</IconSettings>
       </div>
  );

}

export {ToastMessages};
