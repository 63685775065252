import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import {getNamesForUserId, writeName,deleteName, updateName} from './nameAPI';
import {WEBSOCKET_MESSAGE} from "@giantmachines/redux-websocket";
import { v4 as uuidv4 } from 'uuid';

export function generateSaveNameThunk(name){
    return async function saveNameThunk(dispatch, getState) {
        const response = await writeName({name});
        console.log(response);
        if(response.ok){
            const name = await response.json();
            dispatch({ type: 'names/added', payload:name });

        }
        else{
            //TODO HANDLE ERRORS
        }
    }
}

export function generateMigrateNestThunk(targetNestId,nameIds){
    return async function saveNameThunk(dispatch, getState) {
        console.log('handleNestMigration/generateMigrateNestThunk calling api',targetNestId,nameIds);
        await Promise.all(nameIds.map(nameId => {
            return updateName(nameId, {"nest_id":targetNestId})
                .then(async (result)=>{
                    console.log('handleNestMigration/generateMigrateNestThunk/updateName.then',result);
                    const name = await result.json();
                    dispatch({ type: 'names/updated', payload:name });
                })
                .catch(e => {
                    console.log('handleNestMigration/generateMigrateNestThunk/updateName.catch error updating id='+nameId,e);
                    //TODO HANDLE ERRORS
                })
            })
        );
    }
}

export function generateUpdateNameThunk(id, updated_properties){
    return async (dispatch, getState) => {
        const response = await updateName(id, updated_properties);
        console.log(response);
        if(response.ok){
            const name = await response.json();
            dispatch({ type: 'names/updated', payload: name });
        }
        else{
            console.log('error while updating name',response.error());
            //TODO HANDLE ERRORS
        }
    }
}

export function generateDeleteNameThunk(id){
    return async function deleteNameThunk(dispatch, getState) {
        const response = await deleteName(id);
        console.log(response);
        if(response.ok){
            dispatch({ type: 'names/deleted', payload: {id} });
        }
        else{
            //TODO HANDLE ERRORS
        }
    }
}

export const fetchNamesForUser = createAsyncThunk(
    'names/fetchNames',
    async (userId, thunkAPI) => {
        const response = await getNamesForUserId(userId);
        console.debug('fetchNamesForUser response from backend',response);
        if (response.ok)
        {
            console.info('got ok response from backend');
            return response.json();
        }
        else {
            //handle cases
            switch (response.status){
                default:
                case 500:
                    throw new ErrorEvent("Server Error");
            }
        }

    }
);

export const generateBlankName = () => {
    return {
        nest_id: "",
        nestName: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        id: uuidv4(),
        is_new: true
    }
}

export const nameSlice = createSlice({
        name: 'names',
        initialState: {
            //status: 'idle' | 'loading' | 'succeeded' | 'failed',
            status: 'idle',
            error: null,
            records: null,
        },
        reducers: {
            added(state, action) {
                console.log('added record', action.payload);
                state.status = 'name added successfully';
                state.records[action.payload.id]=formatPhoneOnRecord(action.payload);
            },
            updated(state, action) {
                console.log('updated record', action.payload);
                state.status = 'name updated successfully';
                state.records[action.payload.id]=action.payload;
                state.records[action.payload.id]=formatPhoneOnRecord(action.payload);
                console.log('updated record', state.records[action.payload.id]);
                
            },
            deleted(state, action){
                console.log('deleted record id=', action.payload.id);
                delete state.records[action.payload.id];
            },
            moveNamesToAnotherNest(state, action){
                console.log('moveNamesToAnotherNest----', action.payload);
                 state.selectedNamesList = action.payload;
            },
            setNestOnNameSelect(state, action){
                console.log('selectedNest----', action.payload);
                state.selectedNest = action.payload;
            },
            resetNestsAfterMoved(state, action){
                console.log('resetNestsAfterMoved----', action.payload);
                state.resetAllNestCheckboxes = action.payload;
            },
            resetStatus(state, action) {
                console.log('resetStatus', action.payload);
                state.status = action.payload;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase('nests/deleted',(state,action) =>{
                    //when a nest is deleted we need to update any names that were in that nest
                    for(const id in state.records){
                        if(state.records[id].nest_id===action.payload.id){
                            state.records[id].nest_id = "";
                            updateName(id,{ "nest_id":""})
                                .then(()=>{
                                    console.log('updated name to remove nest name.id='+id);
                                })
                                .catch((e)=>{
                                    console.error('error updating name.id='+id,e);
                                });
                        }
                    }
                })
                .addCase("REDUX_WEBSOCKET::"+WEBSOCKET_MESSAGE, (state, action) => {
                    console.log("names/REDUX_WEBSOCKET::"+WEBSOCKET_MESSAGE,action);
                    if("message" in action.payload){
                        const message = JSON.parse(action.payload.message);
                        if("item_type" in message && message.item_type === "name"){
                            if("action_type" in message && message.action_type === "INSERT"){
                                if("item" in message){
                                    state.records[message.item.id] = formatPhoneOnRecord(message.item);
                                }
                                if("items" in message){
                                    for(const item in message.items){
                                        state.records[message.items[item].id] = formatPhoneOnRecord(message.items[item]);
                                    }
                                }
                            }
                        }
                    }
                })
                .addCase(fetchNamesForUser.pending, (state) => {
                    state.status = 'loading';
                })
                .addCase(fetchNamesForUser.rejected,(state => {
                    state.status = 'failed';
                }))
                .addCase(fetchNamesForUser.fulfilled, (state, action) => {
                    state.status = 'succeeded';
                    console.log('succeeded getting names',action.payload);
                    const records = {}
                    for(const[id,record] of Object.entries(action.payload))
                    {
                        records[id] = formatPhoneOnRecord(record);
                    }
                    state.records = records;
                });
        },
    })

/*
currentName.first_name && currentName.last_name && values.first_name && values.last_name
                && currentName.first_name.toLowerCase() === values.first_name.toLowerCase()
                    && currentName.last_name.toLowerCase() === values.last_name.toLowerCase()
                    && ((currentName.phone && values.phone && (currentName.phone === values.phone))
                    || (currentName.email && values.email && (currentName.email.toLowerCase()  === values.email.toLowerCase()))))){
 */
const phoneRegex = RegExp(/[^0-9]/g);

function formatPhoneOnRecord (record){
    record.formatted_phone = record.phone;

    if(record.import_messages !== undefined ){
        if(record.import_messages.phone !== undefined) {
            //leave unformatted if there are errors
            return record;
        }
    }
    const cleanedPhone = record.phone.replace(phoneRegex,'');
    if(cleanedPhone){
        record.formatted_phone = cleanedPhone.substring(0, 3) + "-" + cleanedPhone.substring(3, 6) + "-" +cleanedPhone.substring(6, cleanedPhone.length);
    }
    return record;
}
export const genHashMap = (x) => {
    return `${x.first_name}/${x.last_name}/${x.phone.replace(/\D/g,'')}/${x.email}`.toLowerCase()
}
export const selectAllNames = (state) =>  state.names.records ? Object.values(state.names.records) : [];
export const selectMatchingHashes = (id) => (state) =>  state.names.records ? Object.values(state.names.records).filter(name => name.id!==id).map(x => genHashMap(x) ) : [];
export const selectNameById = (id) => (state) =>  ( state.names.records!=null && id in state.names.records)?state.names.records[id]:null;

export default nameSlice.reducer;
