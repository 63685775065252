const config = {
  "SERVER_URL": () =>  {
    if (window.location.hostname.startsWith("www")) {
      return "https://api.p200.newyorklife.com";
    }
    else if (window.location.hostname.startsWith("local")) {
      return "https://api.dev.p200.newyorklife.com";
    }
    else {
      return "https://api."+window.location.hostname;
    }
  }
}

export default config;
