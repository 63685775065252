import React from 'react';
import "./name.css";

function NameHeader(props) {
    return (
        <div className="slds-m-top_large slds-m-bottom_medium slds-grid slds-wrap">
            <div className="headerline1 slds-m-bottom_small slds-text-heading_large slds-col slds-size_1-of-1">
                Step 2: Enter or Upload Consumers
            </div>
            <div className="slds-col slds-size_1-of-1">
                Enter Consumers individually below or bulk upload multiple Consumers from an Excel spreadsheet (.CSV)
                using the Agent Target Prospecting Tool Template. 
                <br/>
            </div>
        </div>
    );
}

export {NameHeader};
