import './nest.css';
import React, {useState, useEffect} from 'react';
import {Button, IconSettings, Spinner} from "@salesforce/design-system-react";
import {NestInput} from "./NestInput";
import {useSelector} from "react-redux";
import { selectAllNests} from "./nestSlice";
import { closeSpinner} from "./nestSlice";
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom'


function Header() {
    return (
        <div style={
            {
                paddingBottom:"1.5em",
                color: '#565656',
                fontSize:'18px'
            }
        } className="slds-p-left_xx-small">
            <h4><strong>Define Your Nests:</strong></h4>
            
        </div>);
}
function NestMainPanel(props) {
    console.log('NestMainPanel props:',props);
    const [disbleProceed, setDisbleProceed] = useState(false);
    const [errorIds,setErrorIds] = useState([]); 
    const [showBlank, setshowBlank] = useState(false);
    const [onAddNest, setOnAddNest] = useState(false); 
    let disablenest = false;
    let spinner = useSelector(closeSpinner);
    let nests =  useSelector(selectAllNests);
    // //Sorting Nests
    // unSortedNests.sort(function(a, b){
    //     let dateA = a.name.toLowerCase();
    //     let dateB = b.name.toLowerCase();
    //     if (dateA < dateB) 
    //     {
    //       return -1;
    //     }    
    //     else if (dateA > dateB)
    //     {
    //       return 1;
    //     }   
    //     return 0;
    // });

    // let nests = unSortedNests;
    console.log('NestMainPanel nests:',nests);
    const [blankInputs, setBlankInputs] = useState([getBlankInput(nests)]);
    
    if(nests.length >=5){
        disablenest = true;
    }
    function handleError(error){
        let err = errorIds.concat(error);  
        setErrorIds(err);     
    }      
    function removeError(error){      
        const rErr =   errorIds.filter(item => item !==error);        
        setErrorIds(rErr);        
    }
    useEffect(()=>{
        console.log('props.id array length ' + errorIds.length);
        if(errorIds.length > 0){
            setDisbleProceed(true);
        } else {
            setDisbleProceed(false);
        }  
    },[errorIds])
    function getBlankInput(nests) {
        let newNestId = uuidv4();
        console.log('length===>'+nests.length);
        if(nests.length>=5){
            return;
        }
        return (<NestInput errorCallback={handleError} removeCallback={removeError} key={newNestId} name='' id={newNestId}/>)
    }
    useEffect(()=>{                
        //setOnAddNest(false);
        if( (nests.length) === 0){
            setshowBlank(true);
        } else {
            setshowBlank(false);   
        }

    },[nests,showBlank]);
    useEffect(()=> {
        if(!spinner){
            setOnAddNest(false);
        }
    },[spinner]);
    function handleAddNestClick(){    
        if(!onAddNest){    
        setOnAddNest(true);      
        setBlankInputs([getBlankInput(nests)]);     
        }    
    }    
    function handleSpinner(){    
        console.log('adding Spinner '+ spinner);    
        if (spinner) {
            return (
                <div className='panel'>
                    <div style={{position: 'relative', height: '10rem'}}>
                        <Spinner
                            size="medium"
                            variant="brand"
                            assistiveText={{label: 'Loading your nests'}}/>
                    </div>
                </div>);
        } else {
            return (
            <div>
            <div className="slds-form-element slds-form-element_stacked">
                <div className="slds-grid slds-gutters">
                    <div className="slds-col slds-size_11-of-12">
                    <IconSettings iconPath="/icons">
                        <Button
                            className="slds-button_neutral slds-button_stretch"
                            label="Add Nest"
                            disabled = {disablenest}
                            variant = "neutral"
                            iconCategory="utility"
                            iconName="add"
                            iconPosition="left"
                            id="add-nest-button"
                            onClick={()=> handleAddNestClick() }
                        />
                    </IconSettings>
                    </div>
                    </div>
                </div>
                <br/>
                <div className="slds-form-element slds-form-element_stacked">
                <div className="slds-grid slds-gutters">
                    <div className="slds-col slds-size_11-of-12">
                    <IconSettings iconPath="/icons">
                        <Link to="/names"  style={{ textDecoration: 'none' }}>
                                <Button className="slds-button_brand slds-button_stretch"
                                disabled={disbleProceed}
                                label="Proceed to Step 2"
                                id="goto-name-screen"/>
                        </Link>
                    </IconSettings>
                </div>
                </div>
                </div>
            </div>);
        }        
    }
    return (
        <div className="nest-title-group">
                <div className="slds-form panel">
                    <Header/>
                    {nests.map(nest => <NestInput errorCallback={handleError} removeCallback={removeError} key={nest.id} {...nest} />)}
                    {showBlank? blankInputs:null}
                    {onAddNest? blankInputs:null}
                    {handleSpinner()}                
                </div>
            </div>
    );
}
export { NestMainPanel };
