import {createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import {getS3URLForFile, uploadComplete, uploadToS3} from './uploadAPI';

export const UPLOAD_STATES = {
    "IDLE": 'idle',
    "INITIALIZING": 'initializing',
    "GENERATING": 'generating',
    "GENERATED":'generated',
    "UPLOADING":'uploading',
    "UPLOADED":'uploaded',
    "QUEUEING": 'queueing',
    "QUEUED":'queued',
    "ERROR": 'failed',
    "CANCELLED": 'canceled'
}
export const getS3UploadLink = createAsyncThunk(
    'upload/fetchURL',
    async (sourceFile,thunkAPI) => {
        if(thunkAPI.getState().upload.upload_status===UPLOAD_STATES.CANCELLED) {
            console.log('getS3UploadLink: request cancelled');
            thunkAPI.dispatch({type:'upload/complete'})
            return;
        }
        console.log(`getS3UploadLink: generating link for sourceFile`,sourceFile);
        console.log(`getS3UploadLink: generating link for sourceFile.name=${sourceFile.name} contentType=${sourceFile.type}`,sourceFile);
        const response = await getS3URLForFile(sourceFile.type);
        if (response.ok) {
            console.log('getS3UploadLink got ok response from backend');
            const fileLinkRes = await response.json();
            const upload_request = {sourceFile, upload_data: fileLinkRes}
            console.log('getS3UploadLink dispatching uploadFileToS3 with parameter',upload_request);
            thunkAPI.dispatch(uploadFileToS3(upload_request));
            return fileLinkRes;
        } else {
            //handle cases
            switch (response.status) {
                default:
                case 500:
                    throw new ErrorEvent("Server Error");
            }
        }
    });

export const uploadFileToS3 = createAsyncThunk(
    'upload/pushToS3',
    async (request,thunkAPI) => {
        if(thunkAPI.getState().upload.upload_status===UPLOAD_STATES.CANCELLED) {
            console.log('uploadFileToS3: request cancelled');
            thunkAPI.dispatch({type:'upload/complete'})
            return;
        }
        console.log('uploadFileToS3: request received',request);
        const response = await uploadToS3(request.upload_data.post_info, request.sourceFile);
        console.log('Upload to S3 Response is ',response);
        if(response.status === 204){
            console.log('uploadFileToS3 success');
            thunkAPI.dispatch(startImport(request.upload_data));
            return request.upload_data;
        }
        else{
            let s3response = await response.text();
            console.log('error uploading file error:',s3response);
        }
    });

export const startImport = createAsyncThunk(
    'upload/startImport',
    async (request, thunkAPI) => {
        if(thunkAPI.getState().upload.upload_status===UPLOAD_STATES.CANCELLED) {
            console.log('startImport: request cancelled');
            thunkAPI.dispatch({type:'upload/complete'})
            return;
        }
        console.log('upload/startImport: request',request);
        const response = await uploadComplete(request);
        console.log('upload/startImport Response is ',response);

    });

export const uploadSlice = createSlice({
        name: 'upload',
        initialState: {
            //status: 'idle' | 'loading' | 'succeeded' | 'failed',
            status: 'idle',
            //status: 'idle' | 'initializing'  |'generating' |'generated' | 'uploading' | 'uploaded' | 'queueing' |queued | cancelled,
            upload_status: 'idle',
            upload_data: null,
            upload_error: null,
            error: null
        },
        reducers: {
            completed: (state)=> {
                state.upload_status = UPLOAD_STATES.IDLE;
                return state;
            },
            cancel:(state)=> {
                state.upload_status = UPLOAD_STATES.CANCELLED;
                return state;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(getS3UploadLink.pending, (state) => {
                    console.log('Upload/uploadSlice extraReducer.getS3UploadLink.pending');
                    if(state.upload_status===UPLOAD_STATES.CANCELLED)
                        return;

                    state.upload_status = UPLOAD_STATES.GENERATING;
                })
                .addCase(getS3UploadLink.rejected,(state => {
                    console.log('Upload/uploadSlice extraReducer.getS3UploadLink.rejected');
                    state.upload_status = UPLOAD_STATES.ERROR;
                    state.upload_error='getS3UploadLink'
                }))
                .addCase(getS3UploadLink.fulfilled, (state, action) => {
                    console.log('Upload/uploadSlice extraReducer.getS3UploadLink.fulfilled');
                    if(state.upload_status === UPLOAD_STATES.GENERATING)
                        state.upload_status = UPLOAD_STATES.GENERATED;

                    console.log('uploadSlice succeeded getting upload data',action.payload);
                    state.upload_data = action.payload;
                })
                .addCase(uploadFileToS3.pending, (state)=>{
                    console.log('Upload/uploadSlice extraReducer.uploadFileToS3.pending');
                    if(state.upload_status===UPLOAD_STATES.CANCELLED)
                        return;

                    state.upload_status = UPLOAD_STATES.UPLOADING;
                })
                .addCase(uploadFileToS3.rejected,(state,action) => {
                    console.log('Upload/uploadSlice extraReducer.uploadFileToS3.rejected',state,action);
                    state.upload_status = UPLOAD_STATES.ERROR;
                    state.upload_error='uploadFileToS3'
                })
                .addCase(uploadFileToS3.fulfilled, (state, action) => {
                    console.log('Upload/uploadSlice extraReducer.uploadFileToS3.fulfilled',action);
                    if(state.upload_status === UPLOAD_STATES.UPLOADING)
                        state.upload_status = UPLOAD_STATES.UPLOADED;
                })
                .addCase(startImport.pending, (state)=>{
                    console.log('Upload/uploadSlice extraReducer.startImport.pending');
                    if(state.upload_status===UPLOAD_STATES.CANCELLED)
                        return;

                    state.upload_status = UPLOAD_STATES.QUEUEING;
                })
                .addCase(startImport.rejected,(state,action) => {
                    console.log('Upload/uploadSlice extraReducer.startImport.rejected',state,action);
                    state.upload_status = UPLOAD_STATES.ERROR;
                    state.upload_error = 'startImport';
                })
                .addCase(startImport.fulfilled, (state, action) => {
                    console.log('Upload/uploadSlice extraReducer.startImport.fulfilled',action);
                    if(state.upload_status === UPLOAD_STATES.QUEUEING)
                        state.upload_status = UPLOAD_STATES.QUEUED;
                })

        },
    })


export const selectLinkData = (state) =>  state.upload.upload_data ? state.upload.upload_data : null;
export const selectUploadStatus = (state) =>  state.upload.upload_status ? state.upload.upload_status : null;
export const selectUploadError = (state) =>  state.upload.upload_error ? state.upload.upload_error : null;

export default uploadSlice.reducer
