import React,{ useState} from 'react';
import {useSelector} from 'react-redux';
import {generateBlankName, selectAllNames} from "./nameSlice";
import {NamesSidePanel} from "./NamesSidePanel";
import {NameHeader} from "./NameHeader";
import {NameFooter} from "./NameFooter";
import {NameBulkUpload}  from "./NameBulkUpload";
import {NamesDataTable} from "./NamesDataTable";

function Names(props) {

    const names = useSelector(selectAllNames);
    let nameWithErrors = [];
    names.map(name => {
		//Consumers with Errors from Bulk upload
		if(name.import_messages && Object.keys(name.import_messages).length !== 0){
            nameWithErrors.push(name);
		}
    });
    console.log('Names/nameWithErrors',nameWithErrors);
    console.log('Names',names);
    const [nameToEdit, setNameToEdit] = useState(generateBlankName());
    const [selectedNest, setSelectedNest] = useState('');

    const handleNestSelected = (event) =>{
        console.log('Names handleNestSelected event',event);
        if(event.id!=="None"){
            setSelectedNest(event);
        }
        else if (event.id==="None") {
            setSelectedNest('');
        }
    }
    const handleNameSelected = (event) =>{
        console.log('Names handleNameSelected event',event,names[event.id]);   
        const nameToEditVar = names.filter(x=>x.id===event.id);
        const nameToEdit = {
            nest_id: nameToEditVar[0].nest_id,
            nestName: nameToEditVar[0].nestName,
            first_name: nameToEditVar[0].first_name,
            last_name: nameToEditVar[0].last_name,
            phone: nameToEditVar[0].phone,
            formatted_phone: nameToEditVar[0].formatted_phone,
            import_messages: nameToEditVar[0].import_messages,
            import_status: nameToEditVar[0].import_status,
            sf_candidate_id: nameToEditVar[0].sf_candidate_id,
            email: nameToEditVar[0].email,
            street: nameToEditVar[0].street,
            city: nameToEditVar[0].city,
            state: nameToEditVar[0].state,
            zip: nameToEditVar[0].zip,
            id: nameToEditVar[0].id,
            is_new: false
        }

        console.log('nameToEdit----',nameToEditVar)
        if(nameToEdit)
            setNameToEdit(nameToEdit);
        else
            setNameToEdit(generateBlankName());

    }
    const onEditCancelled = (event) =>{
        console.log('Names cancelling/resetting forms')
        setNameToEdit(generateBlankName());
        setSelectedNest('');
    }


    return (
        <div className="namebackground">
            <div className="slds-m-left_x-large">
                <div className="slds-grid slds-wrap">
                    <div className="slds-col slds-size_12-of-12">
                        <span><NameHeader/></span>
                    </div>
                    <div className="slds-col slds-size_2-of-12">
                        <span><NamesSidePanel nameToEdit={nameToEdit} selectedNest={selectedNest}
                                              editCancelled={onEditCancelled} recordSaved={onEditCancelled}/></span>
                    </div>
                    <div className="slds-p-left_xx-large slds-size_9-of-12">
                        <span><NamesDataTable names={names} nestSelected={handleNestSelected}
                                              nameSelected={handleNameSelected} editCancelled={onEditCancelled}/></span>
                    </div>
                    <div className="slds-col slds-size_2-of-2">
                        <NameBulkUpload/>
                    </div>
                </div>
            </div>
            <div className="slds-col slds-size_2-of-2">
                <NameFooter numberOfNames={names.length} nameWithErrors={nameWithErrors.length}/>
            </div>
        </div>
    );
}
export {Names};
