import config from "../../config.js";
import { v4 as uuidv4 } from 'uuid';

export async function getS3URLForFile(contentType) {
    return await fetch(config.SERVER_URL()+`/files/link?request_id=${uuidv4()}&content_type=${contentType}`,{
        mode:'cors',
        credentials: 'include'
    });
}
export async function uploadToS3(upload_data, fileObject) {
    let form = new FormData();
    console.log('uploadApi uploadFileToS3 request ',upload_data);
    for(const field_name in upload_data.fields){
        form.append(field_name,upload_data.fields[field_name]);
    }
    form.append("file",fileObject);
    return await fetch(upload_data.url,{
        method: 'POST',
        mode:'cors',
        body: form
    });

}

export async function uploadComplete(fileRequest) {
    console.log('upload/startImport/uploadComplete',fileRequest);
    return await fetch(config.SERVER_URL()+"/files/complete",{
        mode:'cors',
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(fileRequest)
    });
}
