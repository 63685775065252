import React from 'react';
import './completed.css';
import {Button} from "@salesforce/design-system-react";
import {selectUser} from "../users/usersSlice";
import {useSelector} from "react-redux";

function launchSalesCentral() {
    let SFurl = '';
    if (window.location.hostname.startsWith("p200")) {
        SFurl = "https://nylic.lightning.force.com/one/one.app";
    } else {
        SFurl = "https://nylic--model.lightning.force.com/one/one.app";
    }
    window.open(SFurl, "_blank");
}

function Completed(props) {
    const user = useSelector(selectUser);
    const userStatus = user.import_status;
    console.log('Completed/userStatus in completed screen ==>', userStatus);
    console.log('Completed/userStatus in completed screen ===>', user.import_status);
    var createReactClass = require('create-react-class');
    var Results = createReactClass({
        render: function () {
            return (
                <div className="card-text">
                    <Button className="buttonstyle" label="Go To Sales Central" variant="brand"
                            onClick={() => launchSalesCentral()}/>
                </div>
            );
        }
    });
    var Message = createReactClass({
        render: function () {
            return (
                <div className="card-date">Your contacts have been transferred into Sales Central as Consumers
                            so you can reach out and schedule meetings.
                        </div>
            );
        }
    });
    return (
        <div className="completedbackground" style={{backgroundColor: '#E0EDF3'}}>
            <br/>
            <br/>            
            <div style={{marginTop:"11%"}}>
            <div className="card slds-align_absolute-center slds-p-bottom_medium">
                <div className="card-header">
                    <div className="card-title-group">
                        <h5 className="card-title col-md-6 col-md-offset-3">Congratulations</h5>
                        <br/>
                        <div className="card-date">You have successfully completed your Agent Target Prospecting Tool initiative.</div>
                        <br/>          
                        {userStatus === 'initiated' ?  <Message/> : null}  
                        <br/>
                        <br/>
                        {userStatus === 'initiated' ? <Results/> : null}            
                    </div>
                </div>
                
            </div>
            </div>
            <br/>
            <br/>
        </div>);
}

export {Completed};
