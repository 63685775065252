import React from 'react';
import {ButtonGroup, Dropdown, IconSettings} from "@salesforce/design-system-react";

import downloadtemplate from '../AgentTargetProspectingTemplate.csv';
import {useHistory} from "react-router";

function NameBulkUpload(props){
    const history=useHistory();
    return (
        <div class="slds-p-top_medium slds-p-left_medium slds-p-bottom_xx-large">
          <IconSettings iconPath="/icons">
            <ButtonGroup variant="list">
                {/*eslint-disable-next-line*/}
                <Dropdown
                    align="center"
                    iconCategory="utility"
                    iconName="down"
                    containerClassName ="dropdowncss"
                    width="x-small"
                    iconPosition="right"
                    label="Bulk Upload Consumers"
                    onSelect={(item) =>{
                        if(item.value === 'downloadTemp'){
                            window.location = downloadtemplate;
                        }
                        else if(item.value === 'upload'){
                            history.push("/upload");
                        }
                    }}
                    options={[
                        { label: <span className={"slds-button"}>1. Download Agent Target Prospecting Tool Template</span>, value:'downloadTemp' },
                        { label: <span className={"slds-button"}>2. Upload Agent Target Prospecting Tool File</span>, value: 'upload' },
                    ]}
                />
                </ButtonGroup>
            </IconSettings>
        </div>
    );

}

export {NameBulkUpload};

