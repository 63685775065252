import config from "../../config.js";

export async function getNestsForUserId(userId) {
    return await fetch(config.SERVER_URL()+"/nests",{
        mode:'cors',
        credentials: 'include'
    });
}

export async function writeNest(nest) {
    console.log('writing nest',nest);
    return await fetch(config.SERVER_URL()+"/nests",{
        method: 'POST',
        body: JSON.stringify(nest),
        mode:'cors',
        credentials: 'include'
    });
}

export async function deleteNest(id) {
    console.log(`deleting nest ${id}`);
    return await fetch(config.SERVER_URL()+"/nests/"+id,{
        method: 'DELETE',
        body: JSON.stringify({id}),
        mode:'cors',
        credentials: 'include'
    });
}
