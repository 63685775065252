import React, {useState} from "react";
import downloadtemplate from "../AgentTargetProspectingTemplate.csv";
import "./upload.css";

import {useHistory} from "react-router";
import {Link} from 'react-router-dom';
import {
    getS3UploadLink,
    selectUploadStatus,
    UPLOAD_STATES,
    selectUploadError
} from "./uploadslice";
import {useDispatch, useSelector} from "react-redux";

import {
    Button,
    IconSettings,
    Modal, ProgressRing,
} from "@salesforce/design-system-react";
import {Icon} from "@salesforce/design-system-react";


function isValidExtension(file) {
    console.log('Upload/isValidExtension File Type is ' + file.type);
    return file.name.endsWith(".csv") && ["text/csv", "application/vnd.ms-excel"].includes(file.type);
}


async function hasValidHeaders(file) {
    const colList = ["Nest", "First Name", "Last Name", "Phone", "Email", "Street Address", "City", "State", "Zip Code"];
    let first_bytes = await file.slice(0, 74, file.type).text(); //68 includes the newline char
    console.log('Upload/hasValidHeaders extracted bytes', first_bytes);
    if (first_bytes.includes('\r')) {
        first_bytes = first_bytes.split('\r')[0];
    }
    console.log('Upload/hasValidHeaders after clean up first_line:', first_bytes);

    let fileColList = first_bytes.split(',');
    console.log(`Upload/hasValidHeaders fileColList=${fileColList} colList=${colList}`);
    if (fileColList.length !== colList.length) {
        console.log('Upload/hasValidHeaders headers are wrong size');
        return false;
    }
    const match = colList.every((x) => fileColList.includes(x));
    console.log('Upload/hasValidHeaders match=' + match);

    return match;
}


function Upload(props) {
    const dispatch = useDispatch();
    const upload_status = useSelector(selectUploadStatus)
    const upload_error = useSelector(selectUploadError);

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectionError,setSelectionError] = useState('');
    const [fileError, setFileError] = useState(false);
    const history = useHistory();

    /**
     * Get the proper display component based on upload status
     * @param upload_state
     */
    function getComponentBasedOnState(upload_state){
        switch (upload_state){
            default: {
                return (
                    <div className="slds-p-top_medium slds-p-left_medium slds-p-bottom_xx-large">
                        <div className="slds-align_absolute-center slds-p-bottom_small">
                            <Icon
                                assistiveText={{label: "csv"}}
                                category="doctype"
                                name="csv"
                                size="medium"/>
                        </div>
                        <div className="slds-align_absolute-center slds-p-bottom_medium">
                        <p>{selectedFile.name}</p>
                        </div>
                        <div className="slds-align_absolute-center"><Button label="Remove" onClick={(e) => {setSelectedFile(null);}}/></div>
                    </div>);
            }
            case UPLOAD_STATES.ERROR: {
                return (
                    <div className="slds-p-top_medium slds-p-left_medium slds-p-bottom_xx-large">
                        <div className="slds-align_absolute-center">
                            <Icon
                                assistiveText={{label: "upload error"}}
                                category="utility"
                                name="error"
                                colorVariant="error"
                                size="medium"/>
                        </div>
                        <div className="slds-align_absolute-center"><span style={{color:'red'}}>{translateUploadError(upload_error)}</span></div>
                    </div>);
            }
            case UPLOAD_STATES.GENERATING: {
                return (
                    <div className="slds-p-top_medium slds-p-left_medium slds-p-bottom_xx-large">
                        <div className="slds-align_absolute-center">
                            <ProgressRing flowDirection="fill" value={25} theme="active" size={"large"} />
                        </div>
                        <div className="slds-align_absolute-center">Generating secure link</div>
                    </div>);
            }
            case UPLOAD_STATES.UPLOADED:
            case UPLOAD_STATES.UPLOADING : {
                return (
                    <div className="slds-p-top_medium slds-p-left_medium slds-p-bottom_xx-large">
                        <div className="slds-align_absolute-center">
                            <ProgressRing flowDirection="fill" value={50} theme="active" size={"large"}  />
                        </div>
                        <div className="slds-align_absolute-center">Uploading file</div>
                    </div>);
            }
            case UPLOAD_STATES.QUEUEING: {
                return (
                    <div className="slds-p-top_medium slds-p-left_medium slds-p-bottom_xx-large">
                        <div className="slds-align_absolute-center">
                            <ProgressRing flowDirection="fill" value={75} theme="active" size={"large"}  />
                        </div>
                        <div className="slds-align_absolute-center">Queueing import</div>
                    </div>
                );
            }
            case UPLOAD_STATES.QUEUED: {
                return (
                    <div className="slds-p-top_medium slds-p-left_medium slds-p-bottom_xx-large">
                        <div className="slds-align_absolute-center slds-p-bottom_medium">
                            <ProgressRing value={100} theme="complete" hasIcon size={"large"} />
                        </div>
                        <div className="slds-align_absolute-center">File is queued for import the records should appear in your list soon</div>
                    </div>);
            }
        }
    }

    function translateUploadError(where){
        switch (where){
            default: {
                return '';
            }
            case 'getS3UploadLink':{
                return "Session timed out due to inactivity. Relaunch Agent Target Prospecting Tool and try again." 
            }
            case 'uploadFileToS3':{
                return "Error uploading file, please try again later."

            }
            case 'startImport':{
                return "Error queueing file for import, please try again later."
            }
        }
    }

    const changeHandler = (event) => {
        let file = event.target.files[0];
        fileHandler(file);
    }

    const fileHandler = async (selectedfile) => {
        //console.log('Upload/changeHandler',event);
        let file = selectedfile;
        if (file) {
            if (!isValidExtension(file)) {
                console.log('Upload/changeHandler file extension not valid',file);
                setSelectionError('The file must be in .CSV format. Please select another file.');
                setFileError(true);
                setSelectedFile(null);
                return;
            }

            hasValidHeaders(file).then((result)=>{
                if(!result){
                    console.log('Upload/changeHandler file headers do not match')
                    setSelectionError('Column headers in the file do not match specifications. Update and re-upload the file.');
                    setFileError(true);
                    setSelectedFile(null);
                }
                else{
                    console.log('Upload/changeHandler Setting selected file');
                    setSelectionError('');
                    setFileError(false);
                    setSelectedFile(file);
                }
            })
            .catch(e => {
                console.error('Upload/changeHandler error checking headers',e);
                setSelectionError('Error reading file please try a different file');
                setFileError(true);
                setSelectedFile(null);
            })
        } else {
            //clean up
            //reset uploadData
            setFileError(false);
            setSelectionError('');
            setSelectedFile(null);
        }
    };

    const startUpload = ()=>{
        console.log('Upload/changeHandler dispatching getS3URLForFile to get dest url');
        dispatch(getS3UploadLink(selectedFile));
    }

    const handleClose = () => {        
        history.push("/names");        
    };

    function fileDrop (evt){
        evt.preventDefault();
        console.log('fileDropped', evt.dataTransfer.files[0]);               
        fileHandler(evt.dataTransfer.files[0]);        
    }

    function ondragEvt(evt){        
        evt.preventDefault();
    }

    function handleSubmit() {
        if((selectedFile === null)){
            return true;
        } 
        if((selectedFile !== null && fileError===true)){
            return true;
        }
        if((selectedFile !== null && fileError===false)){
            return false;
        }
    }

    const getFileSelectionComponent = () => {
        if (selectedFile !== null) {
            console.log("getFileSelectionComponent file has been selected");
            return (
                <IconSettings iconPath="./icons">
                    {getComponentBasedOnState(upload_status)}
                </IconSettings>
            );
        } else {
            console.log("getFileSelectionComponent no file has been selected yet");
            return (
                <div>
                    <input
                        type="file"
                        multiple={false}
                        className="slds-file-selector__input slds-assistive-text"
                        accept=".csv,text/csv"
                        onChange={changeHandler}                        
                        id="file-upload-input-01"
                        aria-labelledby="file-selector-primary-label file-selector-secondary-label"/>
                    <label
                        className="slds-file-selector__body"
                        for="file-upload-input-01"
                        id="file-selector-secondary-label">
                        <span className="slds-file-selector__button slds-button slds-button_neutral">Upload .Csv</span>
                        <span className="slds-file-selector__text slds-medium-show">or Drop File</span>
                    </label>
                </div>
            );
        }
    };    

    const getFooterComponent = (upload_status) => {
        switch (upload_status){
            case UPLOAD_STATES.IDLE: {
                return [
                    <IconSettings iconPath="/icons">
                    <div>
                    <a href={downloadtemplate} download="AgentTargetProspectingTemplate.csv" target="_blank" rel="noreferrer">
                        <Button className="slds-float_left" iconCategory="utility" iconName="download" variant="base"
                                iconPosition="left" label="Download Template"/>
                    </a>
                    <span class="slds-p-right_small">
                     <Button label="Cancel" variant="outline-brand" onClick={()=>handleClose()}/>
                    </span>
                    <Button label="Submit" variant="brand" disabled={handleSubmit()} onClick={() => {
                        startUpload();
                    }}/>
                    </div>
                    </IconSettings>
                ];
            }
            case UPLOAD_STATES.QUEUED:{
                return [
                    <a href={downloadtemplate} download="AgentTargetProspectingTemplate.csv" target="_blank" rel="noreferrer">
                        <Button className="slds-float_left" iconCategory="utility" iconName="download"
                                iconPosition="left" label="Download Template"/>
                    </a>,
                    <span class="slds-p-right_small">
                    <Button label="Cancel" disabled={true}/></span>,
                    <Button label="Close" onClick={()=>{history.push("/names");dispatch({type:'upload/completed'})}} />,
                ];
            }
            case UPLOAD_STATES.CANCELLED:{
                return [
                    <a href={downloadtemplate} download="AgentTargetProspectingTemplate.csv" target="_blank" rel="noreferrer">
                        <Button className="slds-float_left" iconCategory="utility" iconName="download"
                                iconPosition="left" label="Download Template"/>
                    </a>,
                    // <Link to="/names"> <Button label="Cancel" disabled={true}  /></Link>,
                    <Link to="/names"> <Button label="Close" onClick={()=>dispatch({type:'upload/completed'})} /></Link>,
                ];
            }
            default: {
                return [
                    <a href={downloadtemplate} download="AgentTargetProspectingTemplate.csv" target="_blank" rel="noreferrer">
                        <Button className="slds-float_left" iconCategory="utility" iconName="download"
                                iconPosition="left" label="Download Template"/>
                    </a>,
                    <span class="slds-p-right_small">
                    <Button label="Cancel" variant="outline-brand" onClick={()=>{history.push("/names");dispatch({type:'upload/cancel'})}} /></span>,
                    <Button label="Submit" variant="brand" disabled={true}/>,
                ];
            }

        }
    }

    return (
        <IconSettings iconPath="./icons">
            <div>            
                <Modal
                    isOpen={true}                    
                    onRequestClose={()=>handleClose()}
                    heading="Upload Multiple Consumers"
                    footer={getFooterComponent(upload_status)}>
                    <section className="slds-p-around_large">
                        <div className="slds-m-around_medium">
                            <span>You can upload multiple consumers at once using a template: </span>
                            {/*eslint-disable-next-line */}
                            <a href={downloadtemplate} download="AgentTargetProspectingTemplate.csv" target="_blank" rel="noreferrer">Download Template</a>
                        </div>
                        <div className="slds-m-around_medium">
                            <span>
                            Ensure the columns in your file are labeled exactly as follows:
                            ‘Nest’, ‘First Name’, ‘Last</span>
                            <br/> Name’, ‘Phone’, ‘Email’, ‘Street Address’, ‘City’, ‘State’, and ‘Zip Code’.
                        </div>
                        <div className="slds-m-around_medium">
                            ‘First Name’, ‘Last Name’, ‘Phone’ OR ‘Email’ are
                            required fields.
                        </div>
                        <div class="slds-m-around_medium slds-form-element">
                            <div class="slds-form-element__control">
                                <div class="slds-file-selector slds-file-selector_images">
                                    <div class="slds-file-selector__dropzone" onDrop={fileDrop} onDragOver={ondragEvt}>
                                       {getFileSelectionComponent()}
                                    </div>
                                    <p style={{color:'red'}}>{selectionError}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </Modal>
            </div>
        </IconSettings>
    );
}

export {Upload};
