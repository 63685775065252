import './App.css';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {fetchUserById, selectUser} from "./features/users/usersSlice";
import {Button, Spinner} from "@salesforce/design-system-react";
import {Completed} from "./features/completed/completed";
import {Nests} from "./features/nests/Nests";
import {Upload} from "./features/upload/upload";
import {Names} from "./features/names/Names";
import {LadingPage} from "./features/landingpage/landingpage";
import { connect,send } from '@giantmachines/redux-websocket';
import {HashRouter, Route, Switch} from "react-router-dom";
import {fetchNestsForUser} from "./features/nests/nestSlice";
import {fetchNamesForUser} from "./features/names/nameSlice";

function App() {
    const dispatch = useDispatch();
    const user = useSelector(selectUser)
    const userStatus = useSelector(state => state.user.status)
    const nestStatus = useSelector(state => state.nests.status);
    const namesStatus = useSelector(state => state.names.status);
    const wssStatus = useSelector(state => state.wss.status);
    let wssKeepAliveInterval = null;

    useEffect(() => {
        if(userStatus === 'idle'){
            console.log('userStatus is idle fetchingUser');
            dispatch(fetchUserById());
        }
        else if(userStatus === 'succeeded'){
            //Connect to WSS for real time feeds
            console.log('App.useEffect userStatus === \'succeeded\'',user);
            dispatch(connect(user.websocket));
        }
    },[userStatus, user, dispatch])

    useEffect(() => {
        if(userStatus === 'succeeded'){
            if(nestStatus === 'idle'){
                dispatch(fetchNestsForUser());
            }

        }
    },[userStatus, nestStatus, dispatch])

    useEffect(() => {
        if(userStatus === 'succeeded'){
            if(namesStatus === 'idle'){
                dispatch(fetchNamesForUser());
            }
        }
    },[userStatus, namesStatus, dispatch])

    useEffect(() => {
        if(wssStatus === 'open'){
            clearInterval(wssKeepAliveInterval);
            wssKeepAliveInterval = setInterval(()=>{
                dispatch(send(JSON.stringify({action:"ping", data: "/ping"})))
            },100000);
        }
        if(wssStatus === 'closed'){
            clearInterval(wssKeepAliveInterval);
        }
    },[wssStatus, dispatch])

    //console.log('captured auth token', wss_auth);
    if(user==null) {
        if (userStatus === 'idle' || userStatus === 'loading') {
            return (
                <div style={{position: 'relative', height: '10rem'}}>
                    <Spinner
                        size="large"
                        variant="brand"
                        assistiveText={{label: 'Loading your information'}}/>
                </div>);
        } else if (userStatus === 'succeeded' || userStatus === 'failed') {
            return (<Button variant="brand" label={"Error retrieving user information, click to try again"} onClick={() => dispatch(fetchUserById())}/>);
        }
        else{
            console.log(`unknown/unexpected userStatus=${userStatus}`);
            return (<Button variant="brand" label={"Click to start..."} onClick={() => dispatch(fetchUserById())}/>);
        }
    }
    else{
        if(user.import_status === "editing"){
            console.log("rendering user interface")
            return (
                <HashRouter>
                    <Switch >
                        <Route exact path="/">
                            <LadingPage/>
                        </Route>
                        <Route path="/nests">
                            <Nests/>
                        </Route>
                        <Route path="/names">
                            <Names />
                        </Route>
                        <Route path="/completed">
                            <Completed/>
                        </Route>
                        <Route path="/upload">
                            <Upload />
                        </Route>
                    </Switch>
                </HashRouter>
            );
        }
        else{
            console.log("user marked as complete",user);
            return (<Completed/>);
        }
    }

}

export default App;
