import { createSlice } from '@reduxjs/toolkit';
import {WEBSOCKET_MESSAGE, WEBSOCKET_OPEN, send, WEBSOCKET_CLOSED} from "@giantmachines/redux-websocket";


export const wssSlice = createSlice({
        name: "wss",
        initialState: {
            //status: 'pending' | 'closed' | 'open',
            status: 'closed'
        },
        reducers: {
        },
        extraReducers: (builder) => {
            builder
                .addCase("REDUX_WEBSOCKET::"+WEBSOCKET_MESSAGE, (state, action) => {
                    console.log("wss/REDUX_WEBSOCKET::"+WEBSOCKET_MESSAGE,action);
                })
                .addCase("REDUX_WEBSOCKET::"+WEBSOCKET_OPEN, (state,action) => {
                    console.log("wss/REDUX_WEBSOCKET::"+WEBSOCKET_OPEN,action);
                    state.status = 'open';
                })
                .addCase("REDUX_WEBSOCKET::"+WEBSOCKET_CLOSED, (state,action) => {
                    console.log("wss/REDUX_WEBSOCKET::"+WEBSOCKET_CLOSED,action);
                    state.status = 'closed';
                })
        },
    })

export default wssSlice.reducer;
