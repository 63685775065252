import { configureStore } from '@reduxjs/toolkit';
import nestReducer from "../features/nests/nestSlice";
import userReducer from "../features/users/usersSlice";
import nameReducer from "../features/names/nameSlice";
import uploadReducer from  "../features/upload/uploadslice";
import wssReducer from "../features/wss/wssSlice";
import reduxWebsocket from '@giantmachines/redux-websocket';

export default configureStore({
    reducer: {
        nests: nestReducer,
        user: userReducer,
        names: nameReducer,
        upload: uploadReducer,
        wss: wssReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        // onOpen: (socket: WebSocket) => {
        //     let interval = null
        //     socket.
        // },

        serializableCheck: {
            ignoredActionPaths: ['meta.arg', 'meta.timestamp','meta.originalAction.meta.timestamp','payload'],
        },
    }).concat(reduxWebsocket()),
})
