import { createSlice,createAsyncThunk} from '@reduxjs/toolkit';
import {getByUserId,setUserForExport} from './usersAPI';

export const selectUser = (state)=> state.user.record

export const fetchUserById = createAsyncThunk(
    'user/get',
    async (thunkAPI) => {
        const response = await getByUserId();
        if(response.ok) {
            // The value we return becomes the `fulfilled` action payload
            return response.json();
        }
        else {
            //todo sort out error handling
            console.error(response.status);
        }
    }
);


export function setUserForExportThunk(userRecord){
    return async (dispatch, getState) => {
        const updateUser = {
            src: "ui"
        };
        const response = await setUserForExport(updateUser);
        console.log('setUserForExportThunk update User response'+response);
        if(response.ok){
            const status = await response.json();
            console.log('setUserForExportThunk update User response name data'+JSON.stringify(status));
            dispatch({ type: 'user/updated', payload:status});
        }
        else{
            //TODO HANDLE ERRORS
        }
    }
}


export const userSlice = createSlice({
        name: 'user',
        initialState: {
            //status: 'idle' | 'loading' | 'succeeded' | 'failed',
            status: 'idle',
            record: null,
            error: null
        },
        reducers: {
            updated(state,action) {
                state.record = Object.assign({},state.record,action.payload);
            },
            added(state, action) {
                let userStatus = action.payload;
                console.log('update user response==>'+JSON.stringify(userStatus));
                state.status = userStatus.import_status;
                state.record = action.payload;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(fetchUserById.pending, (state) => {
                    state.status = 'loading';
                    state.record = null;
                })
                .addCase(fetchUserById.fulfilled, (state, action) => {
                    console.log('succeeded');
                    console.log(action.payload);
                    state.status = 'succeeded';
                    state.record = action.payload;
                })
                .addCase(fetchUserById.rejected,(state,action)=>{
                    console.error(action);
                    state.status='failed';
                    state.record = null;
                })
            ;
        },
    })


export default userSlice.reducer
