import './template.css';
import React from 'react';
import SplitPane, {Pane} from "react-split-pane";
import PropTypes from 'prop-types';

function Template(props){
    return(
        <div style={
            {
                maxWidth:'200px',
                marginRight: 'auto'
            }
        }>
            <SplitPane split="horizontal" minSize={1} defaultSize={1}>
                <Pane className={"panel"}>
                    <div></div>
                </Pane>
                <SplitPane split="vertical" className="verticalpane" minSize={500} defaultSize={500}>
                    <Pane  className={"panel"}>
                        {props.info}
                    </Pane>
                    <Pane  style={{height: '100%',backgroundSize:'100%;100%', backgroundColor: '#E0EDF3'}} >
                        <br/>
                        <br/>
                        <div className={"inputStyle"} style={{background: '#FFFFFF', border: '1px solid #DDDBDA',width:'27%',paddingTop:'0%',paddingBottom: '3%'}}>{props.main} </div>
                    </Pane>
                </SplitPane>
            </SplitPane>
        </div>
    );
}

Template.propTypes = {
    info: PropTypes.element,
    main: PropTypes.element
}
export {Template};
