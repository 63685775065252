import './nest.css';
import React, {useState} from 'react';
import {Button, IconSettings, Input,Modal} from "@salesforce/design-system-react";
import {useDispatch} from "react-redux";
import {generateDeleteNestThunk, generateSaveNestThunk} from "./nestSlice";


function NestInput(props){
    const [nestName, setNestName] = useState(props.name);
    const [nestNameLength, setNestNameLength] = useState(0);
    const origName = props.name;
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    function saveNest(e) {
        console.log('saveNest updating nest id=',e.target.id);
        console.log('saveNest updating nest value=',e.target.value);        
        
        if((nestNameLength !== 0) && (e.target.value === '' || e.target.value ===null || e.target.value === undefined)){              
            deleteNest();
            if(errorMessage){
                setErrorMessage(false);
                props.removeCallback(props.id);
            }
            return
        }        
        if(e.target.value !==null && e.target.value !== undefined && e.target.value !== '' && (e.target.value.length<3 || e.target.value.length>80)){
            setErrorMessage(true);
            props.errorCallback(props.id);
            return;
        }else {
            setErrorMessage(false);
            props.removeCallback(props.id);
        }   
        if(e.target.value === '' || e.target.value ===null || e.target.value === undefined || e.target.value === origName){                    
            return;
        }     
        console.log('updating nes', e);
        const saveNestThunk = generateSaveNestThunk(e.target.id, e.target.value);
        dispatch(saveNestThunk);
    }

    function deleteNest() {        
        setModalOpen(false);
        const action = generateDeleteNestThunk(props.id);
        dispatch(action);
    }

    function confirmDelete(value) {
        if(nestName !==null && nestName !== undefined && nestName !== '' && !errorMessage){
        setModalOpen(value);
        }
    }
    function generateErrorMessage(){        
        if(errorMessage){            
            console.log('***Inside generateErrorMessage')
        return(
            <div style={{color: 'red'}}>
                <p>Enter from 3-80 characters</p>
            </div>
        );
        } else {            
            return(
                <p> </p>
            );
        }
    }

    return (
        <div className='rowC'>
            <IconSettings iconPath="/icons">
                <Modal     
                onRequestClose={()=>setModalOpen(false)}
                footer={[
                    <Button
                        key="promptCancelBtn"
                        label="Cancel"
                        variant="neutral"
                        onClick={()=>setModalOpen(false)}
                    />,
                    <Button
                        key="promptDeleteBtn"
                        variant="brand"
                        label="Delete"
                        onClick={()=>deleteNest()}
                    />,
                ]}
                isOpen={modalOpen}
                size="xx-small"
                heading="Delete Nest Confirmation">
                <div className="slds-m-around_x-large">
                    <p className="slds-p-left_medium">Are you sure you want to delete this Nest?</p> <br/>
                    <p className="slds-p-left_medium">Any Consumers associated with this Nest will be moved to "None".</p>
                <br/>
                </div>
            </Modal>
            </IconSettings>
            <div className="slds-form-element slds-form-element_stacked">
                <div className="slds-grid slds-gutters">
                    <div className="slds-col slds-size_11-of-12 slds-p-right_x-small">

                        <Input
                            on
                            //assistiveText={{ label: 'Enter Nest Name' }}
                            id={props.id}
                            onChange={(e)=>{setNestName(e.target.value)}}
                            onFocus={(e)=>{setNestNameLength(e.target.value.length)}}
                            onBlur={ e => saveNest(e) }
                            value={nestName}
                            placeholder="Enter Nest Name"
                        />
                        {generateErrorMessage()}
                    </div>
                    <div className="slds-p-top_xx-small">
                        <IconSettings iconPath="/icons">
                            <Button
                                assistiveText={{ icon: 'Remove Nest' }}
                                iconCategory="utility"
                                iconName="delete"
                                iconSize="medium"
                                iconVariant="bare"
                                data-nest-id={props.id}
                                data-nest-name={nestName}
                                onClick={() => confirmDelete(true)}
                                variant="icon"
                            />
                        </IconSettings>
                    </div>
                </div>
            </div>
            </div>
    );
}

export { NestInput};
