import React, {useState, useEffect} from 'react';
import {Button,ButtonGroup, Dropdown, IconSettings} from "@salesforce/design-system-react";
import {useSelector} from "react-redux";
import {selectAllNests} from "../nests/nestSlice";
import {useHistory} from "react-router";
import {generateSaveNameThunk} from "./nameSlice";
import {useDispatch} from "react-redux";
import "./name.css";

function MigrateNests(props){
    let unSortedNests = useSelector(selectAllNests);

    //Sorting Nests
    unSortedNests.sort(function(a, b){
        let dateA = a.name.toLowerCase();
        let dateB = b.name.toLowerCase();
        if (dateA < dateB) 
        {
          return -1;
        }    
        else if (dateA > dateB)
        {
          return 1;
        }   
        return 0;
    });
    const nestsList = unSortedNests;
    const history = useHistory();
    const [selectedNamesToMove , setselectedNames] = useState([]);
    const selectedNamesList = useSelector(state => state.names.selectedNamesList);
    const dispatch = useDispatch(); 

    useEffect(()=>{
        console.log('selectedNamesList----'+ JSON.stringify(selectedNamesList));
        setselectedNames(selectedNamesList);
    },[selectedNamesList]);

    let newNests = (nestsList?nestsList:[]).concat([
        {
            "id": "None",
            "name":"None"
        },{
            "id": "Add",
            "name":"+Add New Nest"
        }]
    );

    console.log('NamesSidePanel/NestCombobox: newNests',nestsList);

    const nestsValues = newNests.map(x => {
    return {label: <div className="nestList">{newNests.length > 6 && x.id === 'Add' ? <Button disabled variant="base">  {x.name}</Button> : <Button variant="base">  {x.name}</Button>} </div>, value: x.id, id: x.id};
    });
    
    const handleOnNestSelect = (event) =>{
        console.log('NameList/NestCombobox handleOnSelect event',event);
        //debugger;
        if(event.id === "Add"){
            console.log('NameList going to nests page');
            history.push("/nests");
            return;
        }
        if(props.onNestSelected){
           props.onNestSelected(event);
           console.log('NameList/NestCombobox handleOnSelect selectedNameList',selectedNamesList);
        }
    }

    return (
      <IconSettings iconPath="/icons">
        <ButtonGroup variant="list">
            <Dropdown
                align="center"
                iconCategory="utility"
                iconName="down"
                containerClassName ="dropdowncss"
                width="x-small"
                iconPosition="right"
                label="Move To Another Nest"
                onSelect={(e,data) => handleOnNestSelect(e)}
                options={nestsValues}
            />
            </ButtonGroup>
        </IconSettings>
    )
}

export {MigrateNests};
