import React from 'react';
import {IconSettings, Tooltip} from "@salesforce/design-system-react";
import './landingpage.css';
import {Button} from "@salesforce/design-system-react";
import plantImg from '../NYL_Plant_RGB.png';
import UtilityIcons_adduserImg from '../UtilityIcons_adduser.png';
import UtilityIcons_groupsImg from '../UtilityIcons_groups.png';

import LifeActivityPDFlDoc from '../Ports_of_Life_Candidate_Exercise_PDF.pdf'
import {Link} from "react-router-dom";

function LadingPage(props) {   
    const iconContent = "Use this worksheet to envision and prioritize your  practical warm prospecting opportunities.\n\nThink about your Ports of Life and your existing connections to people, groups, and associations, and record your reflections below in the space provided. Pay attention to the opportunities identified where all 3 prioritization criteria are “Yes” responses."
    return (
        <div className="App">
            <div className="slds-grid slds-gutters">
                <div className="slds-col slds-size_1-of-3 slds-theme_default" style={{height: '790px'}}>
                    <img src={plantImg} className="slds-p-top_xx-large" style={{"margin-left":"-15px"}} alt=""/>
                    <div className="slds-grid slds-gutters">
                        <div className="slds-col slds-size_11-of-12 slds-theme_default">
                            <h1 className="titleStyle what-is-p200 slds-p-top_x-small slds-p-left_xx-large">What is the Agent Target Prospecting Tool?</h1>
                            <p className="pStyle left-margin">Agent Target Prospecting Tool helps you begin building a business through
                                your contacts, but is optional to complete for becoming an active agent. </p> 
                            <p className="pStyle left-margin">Enter all of the people you want to contact as you embark
                                on your new career journey.</p>
                            <p className="pStyle left-margin">Once you have Sales Central access, the Agent Target Prospecting Tool names
                                entered will automatically be created as Consumer records within your CRM so you can
                                begin scheduling meetings.</p>
                        </div>
                        <div className="slds-col slds-size_1-of-12 slds-theme_default">

                        </div>
                    </div>
                </div>
                <div className="slds-col slds-size_2-of-3"
                     style={{backgroundColor: '#E0EDF3', height: '790px', width: '68%', float: 'right'}}>
                    <div className="panel">
                        <br/>
                        <div style={{ marginLeft:'4%', marginTop:'3%'}}>
                            <div className="slds-grid slds-gutters">
                                <div className="slds-col slds-size_6-of-12">
                                    <div className="sub-div-header">
                                        <h1 className="step-1">Step 1</h1>
                                        <br/>
                                        <img src={UtilityIcons_groupsImg} alt="" className=""/>
                                        <h1 className="define-span"><span>Define Your</span></h1>
                                        <h1 className="define-span"><span>Nests</span></h1>
                                        <div className="nest-1">A Nest is a distinct group of people in a sizable
                                            community who:
                                        </div>
                                        <div className="nest-1">1. Are easily identifiable and accessible</div>
                                        <div className="nest-2">2. Have common needs and the financial means to afford
                                            our solutions
                                        </div>
                                        <div className="nest-1">3. Interact and communicate with each other on a regular
                                            basis
                                        </div>
                                        <br></br>
                                        <div className="left-margin">
                                            <div className="slds-grid slds-gutters">
                                                <div style={{"margin-left":"13px"}}>
                                                    <a href={LifeActivityPDFlDoc} download="Ports_of_Life_Candidate_Exercise_PDF.pdf" target="_blank" rel="noreferrer">
                                                        <button className='slds-button slds-button_neutral'>Ports of Life Activity</button>
                                                    </a>
                                                </div>
                                                <div>
                                                    <div className="iconStyle slds-float_left slds-p-left_xx-small slds-m-top_xx-small contentSytle">
                                                        &nbsp;
                                                        <IconSettings iconPath="./icons">
                                                            <Tooltip
                                                                id="base"
                                                                align="top right"
                                                                content={iconContent}
                                                                variant="learnMore"
                                                                size="large"
                                                                dialogClassName="dialog-classname1 slds-float_left slds-popover"
                                                            />
                                                        </IconSettings>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                      
                                    </div>
                                </div>
                                
                                <div className="slds-col slds-size_6-of-12">
                                    <h1 className="step-2">Step 2</h1>
                                    <img src={UtilityIcons_adduserImg} alt="" className="step-2-margin"/>
                                    <h1 className="add-consumers"><span>Add Consumers</span></h1>
                                    <h1 className="add-consumers"><span>to Your Nests</span></h1>
                                    <div className="add-consumers-2">Each name you enter will become a Consumer in Sales
                                        Central.
                                    </div>
                                    <div className="add-consumers-2">You can enter them manually or use the Agent Target Prospecting Tool
                                        Template provided on the main screen.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ margin:'-3%'}}>
                            <Link to="/nests"><Button className="get-started" label="Get Started" variant="brand"/></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {LadingPage};
