import React, {useState} from 'react';
import "./name.css";
import {selectUser, setUserForExportThunk} from "../users/usersSlice";
import {Button, Modal, IconSettings} from "@salesforce/design-system-react";
import {useDispatch} from "react-redux";
import grpicon from './groupicon.png';
import {Link} from 'react-router-dom';
import {useSelector} from "react-redux";
import {selectAllNests} from "../nests/nestSlice";
import {Icon} from "@salesforce/design-system-react";


function NameFooter(props) {
    //User Record
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    let allNests = useSelector(selectAllNests);
    console.log('NameFooter User Status', user);
    let disableFinish=false;
    if(allNests.length>5){
        disableFinish=true;
    }

    const [modalOpen, setModalOpen] = useState(false);

    function confirmedSubmission() {
        console.log('NameFooter confirmedSubmission modalOpen====>' + modalOpen);
        dispatch(setUserForExportThunk(user));
        setModalOpen(false);
    }

    return (
        <div className="footerbackground">
            <div className="slds-p-top_large slds-grid slds-wrap">
                <Link to="/nests"><Button className="slds-m-left_x-large" label="Back" onClick={(e) => {console.log('Back Button e.target:', e.target);}} responsive/></Link>
                <span className="slds-align_absolute-center"><img className="grpimage" alt={""} src={grpicon}/> {props.numberOfNames} Consumers</span>
                <span className="slds-p-right_large">
                    <span class="slds-p-right_medium"><Button label="Save To Finish Later" onClick={(e) => {console.log('Back Button e.target:', e.target);}} responsive/></span>
                    <Button label="Finish" disabled={disableFinish} onClick={(e) => setModalOpen(true)} variant="brand"/>
                </span>
            </div>  
            <IconSettings iconPath="/icons">          
            <Modal   
            onRequestClose={() => setModalOpen(false)}                             
                footer={[
                    <Button
                        key="promptCancelBtn"
                        label="Cancel"
                        variant="outline-brand"
                        onClick={() => setModalOpen(false)} 
                        className="buttonStyle"                       
                    />,
                    <Link to="/completed">
                        <Button
                            key="promptconfirmBtn"
                            variant="brand"
                            label="Submit"
                            onClick={() => confirmedSubmission()}
                        /></Link>,
                ]}
                
                isOpen={modalOpen}
                size="x-small"
                heading="Confirm Submission">
                <div className="slds-m-around_large slds-p-left_large">
                <IconSettings iconPath="/icons">
                {props.nameWithErrors && props.nameWithErrors !== 0 ?
                <div className="slds-grid">                
                    <span class="slds-p-right_xx-small">
                    <Icon
                        assistiveText={{label: "upload error"}}
                        category="utility"
                        name="error"
                        colorVariant="error"
                        size="small"
                        className="errorIconNestStyle"/>      
                        </span>  
                    <span style={{color: '#EE4B2B'}}>
                        There are errors in your entries. If you proceed, these entries will be excluded.
                    </span>
                    
                </div> : ''} <br/> 
                
                <div className="slds-float_left">                
                <span class="slds-p-right_xx-small">
                <Icon
                    assistiveText={{label: "Warning"}}
                    category="utility"
                    name="error"
                    colorVariant="error"
                    name="warning"
                    size="x-small"                
                    />      
                    </span>          
                    </div>                         
                    <div style={{color: '#EE4B2B', paddingLeft:'2px'}}>
                    <b>Caution:</b>  You will not be able to return to the form after clicking 'Submit'.
                    </div><br/>
                    After submission, you can only make changes to Consumers in Sales Central.<br/><br/>
                    To save and return to the form later, click 'Cancel' and click 'Save to Finish Later' <br/><br/>      
                </IconSettings>                  
                </div>
            </Modal>
            </IconSettings>
            </div>     


    );

}

export {NameFooter};
