import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import "./NamesDataTable.css";
import {
    Button,
    IconSettings,
    Modal,
    Checkbox,
    Tooltip
} from "@salesforce/design-system-react";
import deleteicon from './deleteicon.png';
import expandicon from './expandicon.png';
import editicon from './editicon.png';
import collapseicon from './collapseicon.png';
import {MigrateNests} from "./MigrateNests";
import {generateBlankName, generateMigrateNestThunk, selectAllNames} from "./nameSlice";
import {selectAllNests} from "../nests/nestSlice";
import {generateDeleteNameThunk} from "./nameSlice";
import {Link} from 'react-router-dom';
import {Icon} from "@salesforce/design-system-react";

function NamesDataTable(props) {
    console.log('NamesDataTable', props);
    const dispatch = useDispatch();
    let unSortedNests = useSelector(selectAllNests);
    let nestLimitError=false;
    if(unSortedNests.length > 5){
        nestLimitError=true;
    }
    
    console.log('unSortedNests',unSortedNests.length);
    //Sorting Nests
    unSortedNests.sort(function(a, b){
        let dateA = a.name.toLowerCase();
        let dateB = b.name.toLowerCase();
        if (dateA < dateB)
        {
          return -1;
        }
        else if (dateA > dateB)
        {
          return 1;
        }
        return 0;
    });

    let nests = unSortedNests;
    const listOfValidNestIds = nests.map(nest => nest.id);
    let listOfNestsIncludingNone = nests.concat({
        "id": "None",
        "name": "None"
    });

    let unSortedNames = useSelector(selectAllNames);

    console.log('unSortedNames----',unSortedNames);
    //Sorting by lastName
    unSortedNames.sort(function(a, b){
        let dateA = a.last_name.toLowerCase();
        let dateB = b.last_name.toLowerCase();
            if (dateA < dateB)
            {
            return -1;
            }
            else if (dateA > dateB)
            {
            return 1;
            }
        return 0;
    });

    const filteredNamesList = unSortedNames.filter(name => (name.id !== undefined && name.id !== ''))
        .map((name) => {
            if (name.nest_id === undefined || name.nest_id === "" || name.nest_id === "None" || !(listOfValidNestIds.includes(name.nest_id))) {
                return {
                    ...name,
                    nest_id: "None"
                }
            }
            return name;
        })
    console.log('NamesDataTable/filteredNamesList---'+JSON.stringify(filteredNamesList));

    let nestWithErrors = [];
    let nameWithErrors = [];

    filteredNamesList.map(name => {
		//Consumers with Errors from Bulk upload
		if(name.import_messages && Object.keys(name.import_messages).length !== 0){
            nestWithErrors.push(name.nest_id);
            nameWithErrors.push(name);
		}
    });

    console.log('nestWithErrors length--'+nestWithErrors.length);
    console.log('nameWithErrors---Final--' + JSON.stringify(nameWithErrors)+'------'+nameWithErrors.length);
    console.log('nestWithErrors---Final--' + JSON.stringify(nestWithErrors)+'------'+nestWithErrors.length);
    const uniqueNests = Array.from(new Set(nestWithErrors));
    console.log('uniqueNests---Final--' + JSON.stringify(uniqueNests)+'------'+uniqueNests.length);

	var errorCount = (nestWithErrors ? nestWithErrors.length : 0);

    // Accepts the array and key
    const groupByNestId = (array, key) => {
        // Return the end result
        return array.reduce((result, currentValue) => {
            // If an array already present for key, push it to the array. Else create an array and push the object
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
            );
            // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
            return result;
        }, {}); // empty object is the initial value for result object
    };

    let erroredNamesGroupedByNests = [];
    uniqueNests.map(nest => {
        // Group by color as key to the person array
        erroredNamesGroupedByNests = groupByNestId(nameWithErrors, 'nest_id');
    });

    const [showAddressForIndex, showHideAddress] = useState(false);
    const [selectedRow, setSelectedRow] = useState(0);
    const [checkedNames, setCheckedNames] = useState([]);

    const [expandedNestIds, setExpandedNestIds] = useState([]);
    const [selectedNestsIds, setSelectedNestsIds] = useState([]);

    const [nameModalOpen, setNameModalOpen] = useState(false);
	const [deleteConfirmation, setDeleteConfirmation] = useState(false);
	const tStyle = {"padding-top":"2px", "margin-left":"-3px"};
    const [nameId, setNameId] = useState();

    console.log('NamesDataTable nests----mmm---' + JSON.stringify(nests));
    console.log('NamesDataTable names----mmm---' + JSON.stringify(filteredNamesList));

    function deleteNameModal(value) {
        if ((!nameModalOpen) && value) {
            props.editCancelled({});
            setNameId(value);
            setNameModalOpen(true);
        }
    }

    function deleteName() {
        if (nameModalOpen) {
            setNameModalOpen(false);
        }
        props.editCancelled({});
        const deleteNameThunk = generateDeleteNameThunk(nameId);
        dispatch(deleteNameThunk);
    }
    /* Check All / Uncheck All Nest checkbox */
    function handleCheckAllNamesInNest(checked, nest) {
        console.log('NamesDataTable/handleCheckAllNamesInNest checked/nest', checked, nest);
        if (checked) {
			//We need to make sure IDs are unique or the can multiply
            console.log('NamesDataTable/handleCheckAllNamesInNest checked expandedNestIds', expandedNestIds);
            let uniqueExpandedNests = new Set(expandedNestIds);
            uniqueExpandedNests.add(nest.id);
            setExpandedNestIds(Array.from(uniqueExpandedNests));
            console.log('NamesDataTable/handleCheckAllNamesInNest checked expandedNestIds after adding', expandedNestIds);

            console.log('NamesDataTable/handleCheckAllNamesInNest checked setSelectedNestsIds before adding', selectedNestsIds);
            let uniqueSelectedNestsIds = new Set(selectedNestsIds);
            uniqueSelectedNestsIds.add(nest.id);
            setSelectedNestsIds(Array.from(uniqueSelectedNestsIds));
            console.log('NamesDataTable/handleCheckAllNamesInNest checked setSelectedNestsIds after adding', selectedNestsIds);

            console.log('NamesDataTable/handleCheckAllNamesInNest checked checkedNames before adding', checkedNames);
            const nameIdsToCheck = filteredNamesList.filter(name => ((nest.id === name.nest_id))).map(name => name.id);
            let uniqueNameIds = new Set(nameIdsToCheck);
            nameIdsToCheck.forEach(x => uniqueNameIds.add(x));
            checkedNames.forEach(x => uniqueNameIds.add(x));
            setCheckedNames(Array.from(uniqueNameIds));
            console.log('NamesDataTable/handleCheckAllNamesInNest checked checkedNames after adding', checkedNames);

        } else {
            const filteredSelectedNests = selectedNestsIds.filter(x => x !== nest.id);
            setSelectedNestsIds(filteredSelectedNests);

            console.log('NamesDataTable/handleCheckAllNamesInNest checked checkedNames before removing', checkedNames);
            let namesToRemove = filteredNamesList.filter(name => (nest.id === name.nest_id)).map(name => name.id);
            console.log('NamesDataTable/handleCheckAllNamesInNest checked namesToRemove before removing', namesToRemove);
            const filteredCheckedNameIds = checkedNames.filter(name_id => !namesToRemove.includes(name_id));
            console.log('NamesDataTable/handleCheckAllNamesInNest checked filteredCheckedNameIds', filteredCheckedNameIds);
            setCheckedNames(filteredCheckedNameIds);
            console.log('NamesDataTable/handleCheckAllNamesInNest checked checkedNames after removing', checkedNames);
        }
    }

    function handleConsumerSelection(e, nameId, nest) {
        console.log('NamesDataTable handleConsumerSelection Nest Checked---' + e.target.checked);
        const {id, checked} = e.target;
        if (!checked) {
            setCheckedNames(checkedNames.filter(item => item !== id));
            //alert(checkedNames.length);
            if (checkedNames.length === 1) {
                const newNests = selectedNestsIds.filter(x => ((x !== nest.name) && (x !== nest.id)));
                setSelectedNestsIds(newNests);
            }
        } else {
            console.log('NamesDataTable/handleConsumerSelection checked checkedNames before adding', checkedNames);
            setCheckedNames(Array.from(new Set([...checkedNames, id])));
            console.log('NamesDataTable/handleConsumerSelection checked checkedNames after adding', checkedNames);
        }
    }

    /* To expand Names for Each Nest */
    function expandNames(e, nest) {
        setExpandedNestIds(Array.from(new Set([...expandedNestIds, nest.id])));
    }

    /* To collapse Names for Each Nest */
    function collapseNames(e, nest) {
        const collapseNest = expandedNestIds.filter(eachNest => (eachNest !== nest.id));
        setExpandedNestIds(collapseNest);
    }

    function handleTargetNestSelected(selectedNestEvent) {
        console.log('NamesDataTable/handleTargetNestSelected migrating nests', selectedNestEvent, checkedNames);
        const cleanedList = filteredNamesList.filter(name => checkedNames.includes(name.id) && name.nest_id !== selectedNestEvent.id).map(name => name.id);
        console.log('NamesDataTable/handleTargetNestSelected migrating nests after filtering', selectedNestEvent, cleanedList);
        //To clear the Side Panel
        props.editCancelled({});
        dispatch(generateMigrateNestThunk(selectedNestEvent.id, cleanedList));
        setSelectedNestsIds([]);
        setCheckedNames([]);
    }

    /* To Set Nest name on Side Panel when clicked on Nest header*/
    function onNestHeaderClick(nest) {
        console.log('NamesDataTable onNestHeaderClick', nest);
        props.nestSelected(nest);
    }

    /* To Set consumer details on Side Panel when clicked on consumer row*/
    function onNameSelect(currentName, currentNameId, nest) {
        props.editCancelled({});
        console.log(`NamesDataTable onNameSelect currentName=${currentName} currentNameId=${currentNameId} nest=${nest}`);
        if (props.nameSelected) {
            props.nameSelected({"id": currentNameId});
        }
        setSelectedRow(currentNameId);
    }

	function deleteNameModal(value) {
		if(value){
			if(deleteConfirmation){
                props.editCancelled({});
				const deleteNameThunk = generateDeleteNameThunk(value);
				dispatch(deleteNameThunk);

			}else if(!nameModalOpen){
                props.editCancelled({});
				setNameId(value);
				setNameModalOpen(true);
			}
		}
	}
	function deleteName() {
		if(nameModalOpen){
			setNameModalOpen(false);
        }
        //To clear the Side Panel
        props.editCancelled({});
		const deleteNameThunk = generateDeleteNameThunk(nameId);
		dispatch(deleteNameThunk);
	}

    function hasErrors(currentName, fieldName){
        if(currentName.import_messages === undefined || currentName.import_messages === ''){
            return false;
        }
        if(!(fieldName in currentName.import_messages) || currentName.import_messages[fieldName] === ''){
            return false;
        }
        return  true;
    }

    return (
        <div>
            <IconSettings iconPath="/icons">
			<Modal
				onRequestClose={()=>setNameModalOpen(false)}
				footer={[
					<div className="slds-show_inline" >
						<div className="slds-grid" >
							<div className="slds-col">
								<div className="slds-float_left">
									<Checkbox
									id="checkbox-example"
									onChange={(e) => setDeleteConfirmation(e.target.checked)}
									/>
								</div>

								<div class="slds-float_left" style={tStyle}>
								Do not show this message again
								</div>
							</div>
							<div className="slds-col">
								<Button
									key="promptCancelBtn"
									label="Cancel"
									variant="neutral"
									onClick={()=>setNameModalOpen(false)}
								/>
								<Button
									key="promptDeleteBtn"
									variant="brand"
									label="Delete"
									onClick={()=>deleteName()}
								/>
							</div>
						</div>

					</div>

				]}
				isOpen={nameModalOpen}
				size="xx-small"
				heading="Delete Confirmation">
				<div className="slds-m-around_large slds-p-left_medium">
					Are you sure you want to delete this Consumer? This action cannot be undone. <br/>
				</div>
			</Modal>
			</IconSettings>
            <div className="slds-grid slds-gutters nameListDiv">
                <div>
                    <span><MigrateNests names={props.names} onNestSelected={handleTargetNestSelected}/></span>
                </div>
                <div className="addressLink">
                    <div className="slds-text-align_center">
						<span>
							{!showAddressForIndex ? (<div >
                                <Button label="Show Address" variant="base" onClick={() => {
                                    showHideAddress(!showAddressForIndex)
                                }}/>
                            </div>) : (<div className="HideAddress">
                                <Button label="Hide Address" variant="base" onClick={() => {
                                    showHideAddress(!showAddressForIndex)
                                }}/>
                            </div>)}
						</span>
                    </div>
                </div>

            </div>
            {nestLimitError ? <div className="slds-grid errorTitleDiv" >
                    <IconSettings iconPath="/icons">
                    <Icon
	                    assistiveText={{label: "upload error"}}
	                    category="utility"
	                    name="error"
	                    colorVariant="error"
	                    size="small"
	                    className="errorIconNestStyle"/>
                     <span class="slds-p-left_xx-small" ><strong>The max number of Nests is 5.</strong>{' '}Consolidate and remove the extra Nests to proceed.<br/>To remove a Nest, click the pencil icon next to the Nest name and remove the Nest from Step 1.</span>
                     </IconSettings>
            </div>:""}
            {errorCount > 0 ? <div className="errorTitleDiv"><strong>{errorCount} {errorCount < 2 ? ' entry' : ' entries' } requires review.</strong> If not corrected, these entries will be excluded.</div> : ""}
            <IconSettings iconPath="/icons">
               
                <div className="tableDiv">
                    <div className="topBorder"/>
                    {listOfNestsIncludingNone.map((nest, i) => {
                        console.log('NamesDataTable/table filteredNamesList', filteredNamesList);
                        console.log('NamesDataTable/table nest', nest);
                        let filteredNamesForEachNest = filteredNamesList.filter(name => (nest.id === name.nest_id));
                        console.log('NamesDataTable/table ', filteredNamesForEachNest);
                        return (
                            <React.Fragment key={nest.id}>
                                <div className="slds-grid nestDiv">
                                    <div className="checkboxStyle">
                                        <span>
                                            <Checkbox
                                                assistiveText={{
                                                    label: 'Default',
                                                }}
                                                id={nest.id}
                                                labels={{
                                                    label: '',
                                                }}
                                                name="checkAll"
                                                checked={selectedNestsIds.includes(nest.id)}
                                                onChange={(e) => handleCheckAllNamesInNest(e.target.checked, nest)}
                                            />
                                        </span>
                                    </div>
                                    {(!(expandedNestIds.includes(nest.id)) && !(expandedNestIds.includes(nest.name))) && (
                                        <div key={`parentClass${nest.id}`}
                                             className={`parentClass${nest.id} expandStyle`}
                                             value="expand"
                                             onClick={(e) => expandNames(e, nest)}>
                                            <span><img className="expandicon" src={expandicon} alt=""/></span>
                                        </div>)}

                                    {((expandedNestIds.includes(nest.id) || expandedNestIds.includes(nest.name))) && (
                                        <div
                                            key={`parentClass${nest.id} collapseStyle`}
                                            value="collapse"
                                            className={`parentClass${nest.id} collapseStyle`}
                                            onClick={(e) => collapseNames(e, nest)}>
                                            <span><img className="collapseicon" src={collapseicon} alt=""/></span>
                                        </div>)}

                                    <div className={(uniqueNests.includes(nest.id))? 'errorStyle NestHeader': 'NestHeader'} onClick={() => onNestHeaderClick(nest)}>
                                        <span>{nest.name}</span>
                                    </div>
                                    {(<div className="childCount">
                                        <span className={(uniqueNests.includes(nest.id))? 'errorStyle': ''}>({filteredNamesForEachNest.length})</span>
                                    </div>)}
                                    <div className="editDivStyle">
                                        <span><Link to="/nests"><img className="editicon" src={editicon} alt=""/></Link></span>
                                    </div>

                                    <div className="slds-grid slds-gutters nestErrorDiv">
                                            <div className="errorIconForNestStyle">
                                                {uniqueNests.includes(nest.id) ?
                                                    <Icon
                                                    assistiveText={{label: "upload error"}}
                                                    category="utility"
                                                    name="error"
                                                    colorVariant="error"
                                                    size="small"
                                                    className="errorIconNestStyle"/>

                                            : ""}
                                            </div>
                                            <div className="errorCountForNestStyle">
                                                {uniqueNests.includes(nest.id) ? erroredNamesGroupedByNests[nest.id].length : ""}
                                            </div>
                                    </div>
                                                </div>
                                {(filteredNamesForEachNest && filteredNamesForEachNest.length > 0) && (
                                    <div>
                                        {filteredNamesForEachNest.map((currentName, j) => {
                                            return (
                                                <div key={currentName.id}>
                                                    {(expandedNestIds.includes(nest.id)) && (<div
                                                        key={`parentClass${currentName.id}`}
                                                        onClick={() => onNameSelect(currentName, currentName.id, nest)}
                                                        className={selectedRow === currentName.id ? 'highlightRow eachRow' : 'eachRow'}>
                                                        <div className="slds-grid slds-gutters rowStyle">
                                                            <div style={{width: '3%'}}>

                                                            </div>
                                                            <div className="childCheckboxDiv">
                                                                <Checkbox
                                                                    key={currentName.id}
                                                                    id={currentName.id}
                                                                    checked={checkedNames.includes(currentName.id)}
                                                                    onChange={(e) => handleConsumerSelection(e, currentName.id, nest)}
                                                                    value={currentName.id}
                                                                />
                                                            </div>

                                                            <div className="firstNameDiv slds-grid">
                                                                <div className="InnerDiv">
                                                                    {(currentName.import_messages !== undefined && currentName.import_messages.first_name !== undefined) ?
                                                                     <div className="errorIconDiv">
                                                                         <Tooltip
                                                                            id="tooltip"
                                                                            align="top"
                                                                            content={currentName.import_messages !== undefined && currentName.import_messages.first_name !== undefined && currentName.import_messages.first_name}>
                                                                            <Button className="button">
                                                                            <Icon
                                                                            assistiveText={{label: "Error"}}
                                                                            category="utility"
                                                                            name="error"
                                                                            colorVariant="error"
                                                                            size="small"
                                                                            className="errorIconStyle"/>
                                                                        </Button>
                                                                    </Tooltip>
                                                                     </div>
                                                                     : ""}
                                                                </div>

                                                                <div className="DataStyle">
                                                                {currentName.first_name}
                                                                </div>
                                                            </div>

                                                            <div className="lastNameDiv slds-grid">
                                                                <div className="InnerDiv">
                                                                    {(currentName.import_messages !== undefined && currentName.import_messages.last_name !== undefined) ?
                                                                     <div className="errorIconDiv">
                                                                         <Tooltip
                                                                            id="tooltip"
                                                                            dialogClassName="tooltipCustom"
                                                                            align="top"
                                                                            content={currentName.import_messages !== undefined && currentName.import_messages.last_name !== undefined && currentName.import_messages.last_name}>
                                                                            <Button className="button">
                                                                            <Icon
                                                                            assistiveText={{label: "Error"}}
                                                                            category="utility"
                                                                            name="error"
                                                                            colorVariant="error"
                                                                            size="small"
                                                                            className="errorIconStyle"/>
                                                                        </Button>
                                                                    </Tooltip>
                                                                     </div>
                                                                     : ""}
                                                                </div>

                                                                <div className="DataStyle">
                                                                {currentName.last_name}
                                                                </div>
                                                            </div>

                                                            {/* Phone is not blank but incorrect format */}
                                                            <div className="phoneDiv slds-grid">
                                                                <div className="">
                                                                    {(currentName.import_messages !== undefined && currentName.import_messages.phone !== undefined) ?
                                                                        <Tooltip
                                                                            id="tooltip"
                                                                            align="top"
                                                                            content={currentName.import_messages.phone}
                                                                        >
                                                                        <Button className="button">
                                                                            <Icon
                                                                            assistiveText={{label: "Error"}}
                                                                            category="utility"
                                                                            name="error"
                                                                            colorVariant="error"
                                                                            size="small"
                                                                            className="errorIconStyle"/>
                                                                        </Button>
                                                                    </Tooltip>
                                                                : ""}

                                                                {/* Phone is blank*/}
                                                                {(currentName.import_messages !== undefined && currentName.import_messages.record !== undefined) ?
                                                                        <Tooltip
                                                                            id="tooltip"
                                                                            align="top"
                                                                            content={currentName.import_messages !== undefined && currentName.import_messages.record !== undefined && currentName.import_messages.record}>
                                                                            <Button className="button">
                                                                                <Icon
                                                                                assistiveText={{label: "Error"}}
                                                                                category="utility"
                                                                                name="error"
                                                                                colorVariant="error"
                                                                                size="small"
                                                                                className="errorIconStyle"/>
                                                                            </Button>
                                                                        </Tooltip>


                                                                    : ""}
                                                                </div>
                                                                <div className="DataStyle">
                                                                {currentName.formatted_phone}
                                                                </div>
                                                            </div>

                                                            {/* Email is not blank but incorrect format */}
                                                            <div className="emailDiv slds-grid">
                                                                <div className="">
                                                                    {(currentName.import_messages !== undefined && currentName.import_messages.email !== undefined) ?
                                                                        <Tooltip
                                                                            id="tooltip"
                                                                            align="top"
                                                                            dialogClassName="dialog-classname slds-float_left slds-popover"
                                                                            content={currentName.import_messages.email}
                                                                        >
                                                                        <Button className="button">
                                                                            <Icon
                                                                            assistiveText={{label: "Error"}}
                                                                            category="utility"
                                                                            name="error"
                                                                            colorVariant="error"
                                                                            size="small"
                                                                            className="errorIconStyle"/>
                                                                        </Button>
                                                                    </Tooltip>

                                                                : ""}

                                                                {/* Email is blank*/}
                                                                {(currentName.import_messages !== undefined && currentName.import_messages.record !== undefined) ?
                                                                        <Tooltip
                                                                            id="tooltip"
                                                                            align="top"
                                                                            content={currentName.import_messages !== undefined && currentName.import_messages.record !== undefined && currentName.import_messages.record}>
                                                                            <Button className="button">
                                                                                <Icon
                                                                                assistiveText={{label: "Error"}}
                                                                                category="utility"
                                                                                name="error"
                                                                                colorVariant="error"
                                                                                size="small"
                                                                                className="errorIconStyle"/>
                                                                            </Button>
                                                                        </Tooltip>

                                                                    : ""}
                                                                </div>
                                                                <div className="DataStyle">
                                                                {currentName.email}
                                                                </div>
                                                            </div>

                                                            <div className="deleteDiv">
                                                                <span><img className="deleteicon" src={deleteicon}
                                                                           onClick={() => deleteNameModal(currentName.id)}
                                                                           alt=""/></span>
                                                            </div>
                                                        </div>
                                                        {(currentName.street || currentName.city || currentName.state || currentName.zip) && showAddressForIndex && (
                                                            <div className="address">
                                                                <div className="slds-grid slds-gutters rowStyle">
                                                                    <div className="streetDiv">
                                                                    {(currentName.import_messages !== undefined && currentName.import_messages.street !== undefined) ?
                                                                            <Tooltip
                                                                                id="tooltip"
                                                                                align="top"
                                                                                dialogClassName="dialog-classname slds-float_left slds-popover"
                                                                                content={currentName.import_messages.street}
                                                                            >
                                                                            <Button className="button">
                                                                                <Icon
                                                                                assistiveText={{label: "Error"}}
                                                                                category="utility"
                                                                                name="error"
                                                                                colorVariant="error"
                                                                                size="small"
                                                                                className="errorIconStyle"/>
                                                                            </Button>
                                                                        </Tooltip>

                                                                    : ""}
                                                                        <span>
                                                                            {(currentName.street !== undefined && currentName.street !== "") ?  currentName.street : ' '}
                                                                        </span>
                                                                        <span>
                                                                            {((currentName.street !== undefined && currentName.street !== "") &&
                                                                                ((currentName.city !== undefined && currentName.city !== "") ||
                                                                                (currentName.state !== undefined && currentName.state !== "") ||
                                                                                (currentName.zip !== undefined && currentName.zip !== "")))? ', \u00A0'  : ''}
                                                                        </span>
                                                                    </div>
                                                                    <div className="cityDiv">
                                                                    {(currentName.import_messages !== undefined && currentName.import_messages.city !== undefined) ?
                                                                            <Tooltip
                                                                                id="tooltip"
                                                                                align="top"
                                                                                dialogClassName="dialog-classname slds-float_left slds-popover"
                                                                                content={currentName.import_messages.city}
                                                                            >
                                                                            <Button className="button">
                                                                                <Icon
                                                                                assistiveText={{label: "Error"}}
                                                                                category="utility"
                                                                                name="error"
                                                                                colorVariant="error"
                                                                                size="small"
                                                                                className="errorIconStyle"/>
                                                                            </Button>
                                                                        </Tooltip>

                                                                    : ""}
                                                                        <span>{(currentName.city !== undefined && currentName.city !== "") ?  currentName.city  : ''}</span>
                                                                        <span>
                                                                            {((currentName.city !== undefined && currentName.city !== "") &&
                                                                                ((currentName.state !== undefined && currentName.state !== "") ||
                                                                                (currentName.zip !== undefined && currentName.zip !== "")))? ', \u00A0' : ''}
                                                                        </span>
                                                                    </div>
                                                                    
                                                                    <div className="stateDiv">
                                                                        {(currentName.import_messages !== undefined && currentName.import_messages.state !== undefined) ?
                                                                            <Tooltip
                                                                                id="tooltip"
                                                                                align="top"
                                                                                dialogClassName="dialog-classname slds-float_left slds-popover"
                                                                                content={currentName.import_messages.state}
                                                                            >
                                                                            <Button className="button">
                                                                                <Icon
                                                                                assistiveText={{label: "Error"}}
                                                                                category="utility"
                                                                                name="error"
                                                                                colorVariant="error"
                                                                                size="small"
                                                                                className="errorIconStyle"/>
                                                                            </Button>
                                                                        </Tooltip>

                                                                    : ""}
                                                                        <span>{(currentName.state !== undefined && currentName.state !== "") ? currentName.state : ''}</span>
                                                                        <span>
                                                                            {((currentName.state !== undefined && currentName.state !== "") &&
                                                                                (currentName.zip !== undefined && currentName.zip !== ""))? ', \u00A0'  : ''}
                                                                        </span>
                                                                    </div>
                                                                    

                                                                    <div className="zipDiv">

                                                                    {(currentName.import_messages !== undefined && currentName.import_messages.zip !== undefined) ?
                                                                            <Tooltip
                                                                                id="tooltip"
                                                                                align="top"
                                                                                dialogClassName="dialog-classname slds-float_left slds-popover"
                                                                                content={currentName.import_messages.zip}
                                                                            >
                                                                            <Button className="button">
                                                                                <Icon
                                                                                assistiveText={{label: "Error"}}
                                                                                category="utility"
                                                                                name="error"
                                                                                colorVariant="error"
                                                                                size="small"
                                                                                className="errorIconStyle"/>
                                                                            </Button>
                                                                        </Tooltip>

                                                                    : ""}
                                                                        <span>{(currentName.zip !== undefined && currentName.zip !== "") ? currentName.zip  : ''}</span>
                                                                    </div>
                                                                </div>
                                                            </div>)}
                                                    </div>)}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>
            </IconSettings>
        </div>

    );
}

export {NamesDataTable};
